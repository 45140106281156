import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Landing from '../components/home/Landing';
import Contact from '../components/home/Contact';
import NewsListing from '../components/home/newsListing';
import NewsDetails from '../components/home/newsDetails';
import Committees from '../components/home/Committee';
import CommitteeNews from '../components/home/CommitteeNews';
import Ldsc from '../components/home/Ldsc';
import FaithAndYou from '../components/home/FaithAndYou';
import Donation from '../components/home/Donation';
import DonationComplete from '../components/home/DonationComplete';
import Search from '../components/home/search';

const ROUTES = [
  { path: "/", key: "ROOT", exact: true, component: () => <Landing />
  },
  {
    path: "/donation", key: "DONATIONS", exact: true, component: () => <Donation />
  },
  {
    path: "/donationComplete", key: "DONATIONS", exact: true, component: () => <DonationComplete />
  },
  {
    path: "/search", key: "SEARCH", exact: true, component: () => <Search />
  },
  { path: "/contact", key: "CONTACT", exact: true, component: () => <Contact />
  },
  { path: "/contact/:tab", key: "CONTACT", exact: true, component: () => <Contact />
  },
  { path: "/news", key: "NEWS", exact: true, component: () => <NewsListing />
  },
  { path: "/news/:tab", key: "NEWS", exact: true, component: () => <NewsListing />
  },
  { path: "/news/:tab/:tag", key: "NEWS", exact: true, component: () => <NewsListing />
  },
  { path: "/newsItem/:id", key: "NEWS", exact: true, component: () => <NewsDetails />
  },
  { path: "/:type", key: "NEWS", exact: true, component: () => <Committees />
  },
  { path: "/:type/:tab", key: "NEWS", exact: true, component: () => <Committees />
  },
  { path: "/:type/:cat/:tab", key: "NEWS", exact: true, component: () => <Committees />
  },
  { path: "/:type/news/:cat/:id", key: "NEWS", exact: true, component: () => <CommitteeNews />
  }
  // {
  //   path: "/bibleBook",
  //   key: "APP",
  //   component: RenderRoutes,
  //   routes: [
  //     {
  //       path: "/bibleBook",
  //       key: "BIBLE_BOOK",
  //       exact: true,
  //       component: () => <h1>book</h1>,
  //     },
  //     {
  //       path: "/bibleBook/:book_id",
  //       key: "BIBLE_BOOK_ID",
  //       exact: true,
  //       component: () => <BibleBook />,
  //     },
  //     {
  //       path: "/bibleBook/:book_id/:chapter_id",
  //       key: "BIBLE_BOOK_ID",
  //       exact: true,
  //       component: () => <BibleBook />,
  //     },
  //     {
  //       path: "/bibleBook/:book_id/:chapter_id/:content_version",
  //       key: "BIBLE_BOOK_ID",
  //       exact: true,
  //       component: () => <BibleBook />,
  //     },
  //   ],
  // },
  // {
  //   path: "/searchResult",
  //   key: "searchResult",
  //   component: RenderRoutes,
  //   routes: [
  //     {
  //       path: "/searchResult",
  //       key: "SEARCH",
  //       exact: true,
  //       component: () => <SearchResult />,
  //     },
  //     {
  //       path: "/searchResult/:type/:start_bible_id/:end_bible_id/:content_version/:option/:word",
  //       key: "SEARCH_BY",
  //       exact: true,
  //       component: () => <SearchResult />,
  //     },
  //   ],
  // },
  // {
  //   path: "/login", key: "LOGIN", exact: true, component: () => <Signin />
  // },
  // { 
  //   path: "/magazine", key: "MAGAZINE", exact: true, component: () => <Magazine />
  // },
  // { 
  //   path: "/video", key: "VIDEO", exact: true, component: () => <Video />
  // },
  // { 
  //   path: "/egw", key: "EGW", exact: true, component: () => <Egw />
  // },
  // { 
  //   path: "/topic", key: "TOPIC", exact: true, component: () => <Topic />
  // },
  // { 
  //   path: "/sdaDictionary", key: "SdaDictionary", exact: true, component: () => <SdaDictionary />
  // },
  // {
  //   path: "/discoverPlans", key: "biblePlans", exact: true, component: () => <DiscoverPlans />
  // },
  // {
  //   path: "/PlanPreview", key: "PlanPreview", exact: true, component: () => <PlanPreview />
  // },
  // {
  //   path: "/bibleBookMap/:book_id",
  //   key: "BIBLE_BOOK_MAP",
  //   exact: true,
  //   component: () => <BibleBookMap />,
  // },
  // {
  //   path: "/bookmarkedVerses",
  //   key: "BOOKMARKED_VERSES",
  //   exact: true,
  //   component: () => <BookmarkedVerses />,

  // },
  // {
  //   path: "/collectedBibles",
  //   key: "COLLECTED_BIBLES",
  //   exact: true,
  //   component: () => <CollectedBibles />,

  // },
  // {
  //   path: "/collectedMagazines",
  //   key: "COLLECTED_MAGAZINES",
  //   exact: true,
  //   component: () => <CollectedMagazines />,

  // }
];

export default ROUTES;

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={props => <route.component {...props} routes={route.routes} />}
    />
  );
}

export function RenderRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes key={route.key} {...route} />;
      })}
      <Route component={() => <h1>Not Found!</h1>} />
    </Switch>
  );
}