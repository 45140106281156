import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { getNewsCat, getBanner, getNewsByCat, postNewsByWord, RES_BASE_URL, CMS_BASE_URL } from '../../redux/action';

import './news.css'; 
import Header from '../common/Header';
import Footer from '../common/Footer';
import logo from '../../images/whitelogo.png';
import blueLogo from '../../images/logo-blue.png';
import videoSample from '../../images/videoThumbNailSample.png';
import newsSample from '../../images/newsThumbNailSample.png';
import newsSample2 from '../../images/newsThumbnailSample2.jpg';

import SearchIcon from '@material-ui/icons/Search';


// import logo from '../../images/logo.png';

import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";

class NewsListing extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      selectedCategoryId: -1,
      activeCategory: 0,
      isScrolling: false,
      isCollecting: false,
      focusIndex: this.props.match.params.tab || 0,
      activeCategoryId: 0,
      searchWord: "",
      searchTag: this.props.match.params.tag || "",
      searchKeyword: "",
      newsCategory: [{"id":0, "title": getValue("allNews")}],
      newsList: [],
    };
    this._scroller = React.createRef();
    this._scrollerLogo = React.createRef();
  }

  componentWillMount() {

    getNewsCat(this.state.locale).then(response => {
      this.setState({ newsCategory: [...this.state.newsCategory, ...response.data] });
      
      if (this.state.searchTag != "") {
        this.postTagSearch(this.state.searchTag);
      } else {
        this.getNewsFromCategory(this.state.focusIndex);
        
      }
    });


    window.addEventListener('scroll', (e) => {
      this._scroller.current.style.backgroundColor = "rgba(47,85,127,"+(1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) )+")";
       this._scrollerLogo.current.style.opacity = (1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) );
    });
  }

  componentDidMount() { 

    getBanner(this.state.locale,2).then(response => {
      let data = (response.data.length > 0) ? response.data[0].img : ""
      this.setState({ banner: data });

    });

  }

  getNewsFromCategory(categoryId) {
    this.setState({ newsList: [] });
    getNewsByCat(this.state.locale, categoryId).then(response => {
      this.setState({ newsList: response.data.tbl_news, newsTags:  response.data.tbl_tags });
    });
  }

  postSearch() {
    this.setState({ newsList: [] });
    postNewsByWord({ "cat": this.state.activeCategoryId, "locale": this.state.locale, "word": this.state.searchWord, "keyword": this.state.searchKeyword, "tag": this.state.searchTag }).then(response => {
      this.setState({ newsList: response.data.tbl_news, newsTags:  response.data.tbl_tags });
    });
  }

  postTagSearch(tag) {
    this.setState({ newsList: [], searchTag: tag });
    postNewsByWord({ "cat": this.state.activeCategoryId, "locale": this.state.locale, "word": this.state.searchWord, "keyword": this.state.searchKeyword, "tag": tag }).then(response => {
      this.setState({ newsList: response.data.tbl_news, newsTags:  response.data.tbl_tags });
    });
  }

  swapSubMenu(index, catId) {
    this.setState({ focusIndex: index, activeCategoryId: catId });
    this.getNewsFromCategory(catId);
  }

  render() {
    
    return (
      <div className="newsContainer newsListingContainer">

          <Header />
        
          <div className="bodyContent">
             <div className="banner" style={{ "backgroundImage": "url(" + RES_BASE_URL + "storage/" + this.state.banner + ")" }}>
               <div className="wrapper">
                 <h1>{(this.state.newsCategory.length > 1) ? this.state.newsCategory[this.state.focusIndex].title : ""}</h1>
               </div>
             </div>                                                

             <div className="subCatBanner">
              <div className="wrapper">
                
               </div>
             </div>
             <div className="wrapper">
                 <div className="newsArea">
                   <div className="newsListing">
                     
                       <div className="innerWrapper">

                       {
                          (this.state.searchTag != "") ? <div style={{  "marginBottom": "20px", "display": "flex"  }} ><span style={{ "marginLeft": "0" }} className="tagSpan">#{this.state.searchTag}</span></div> : ""
                       }

                         {

                          (this.state.newsList || []).map(item => {
                            var tags = [];
                            if (item.tags) {
                              tags = item.tags.split(",");
                            }
                            return (
                              <div className="newsGroup">
                               <div onClick={() => (window.location.href = "/departmentals/news/"+item.comm_id+"/"+item.id)} className="thumbnailArea">
                                 <img src={CMS_BASE_URL+"storage/"+item.img_1} />
                                 <p>{getValue("last_update_on")} {item.postDate}</p>
                               </div>
                               
                               <div className="descArea">
                                 <h4 onClick={() => (window.location.href =  "/departmentals/news/"+item.comm_id+"/"+item.id)}>{item.title}</h4>
                                 <h5>{item.intro}</h5>
                                 <div className="tagDiv">
                                  {
                                    (tags).map(tag => {
                                      return <span>
                                        {
                                          (this.state.newsTags || []).map(tbl_tag => {
                                            return (tbl_tag.id == tag) ? <span  className="tagSpan" onClick={() => this.postTagSearch(tbl_tag.tag_name)}>{tbl_tag.tag_name}</span> : ""
                                          })
                                        }
                                      </span>
                                    }, this)
                                  }
                                 </div>
                               </div>
                             </div>
                            );
                          })

                         }
             
                       </div>
                    
                    </div>

                    <div className="searchBox">
                      <div className="sectionHeader">
                         <div className="indicator"></div>
                         <h3>{getValue("filter")}</h3>
                       </div>
                       <input type="text" className="filterTextField" onChange={(e) => this.setState({ searchWord: e.target.value })} />

                       <div className="sectionHeader">
                         <div className="indicator"></div>
                         <h3>{getValue("keyword")}</h3>
                       </div>
                       <input type="text" className="filterTextField" onChange={(e) => this.setState({ searchKeyword: e.target.value })} />
                       

                       <div className="sectionHeader">
                         <div className="indicator"></div>
                         <h3>{getValue("tag")}</h3>
                       </div>
                       <input type="text" className="filterTextField" onChange={(e) => this.setState({ searchTag: e.target.value })} />
                       


                       <div className="searchIconDiv" onClick={() => this.postSearch()}>
                         <div className="searchIconInnerDiv">

                           <SearchIcon className="searchIcon" />
                           <span>{getValue("filter")}</span>
                         </div>
                         
                       </div>
                         
                    </div>
               </div>

             </div>
             
          </div>
          <div ref={this._scroller} className="sideBar">
            <img className="logo blueLogo" src={blueLogo} />
            <img ref={this._scrollerLogo} className="logo" src={logo} />
          </div>


        <Footer />
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {

  };
}

export default withRouter(connect(
    mapStateToProps,
    {  }
)(NewsListing));
