import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { postPaypal, capturePaypal, getDonationTypes, getBanner, RES_BASE_URL } from '../../redux/action';

import './Donation.css'; 
import './DonationError.css'; 
import Header from '../common/Header';
import Footer from '../common/Footer';
import logo from '../../images/whitelogo.png';
import blueLogo from '../../images/logo-blue.png';
import leftArrow from '../../images/leftArrow.png';
import rightArrow from '../../images/rightArrow.png';
import videoSample from '../../images/videoThumbNailSample.png';
import newsSample from '../../images/newsThumbNailSample.png';
import paypalBtn from '../../images/paypalBtn.png';

import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import logo from '../../images/logo.png';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";
import Captcha from "demos-react-captcha";

class DonationComplete extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      donationTypes: [],
      selectedCategoryId: -1,
      activeCategory: 0,
      isScrolling: false,
      isCollecting: false,
      focusIndex: 0,
      currentIndex: 0,
      title: "",
      chineseName: "",
      firstName: "",
      lastName: "",
      tel: "",
      address: "",
      email: "",
      receipt: "",
      receiptTitle: "",
      receiptAddress: "",
      amt: 0,
      donateType: "",
      donateTypeOther: "",
      donateDate: "",
      paypalCode: "",
      paymentSubmission: true,
      activeButton: false,
      displayValidateForm: false,
      recapcha: false,
    };
    this._scroller = React.createRef();
    this._scrollerLogo = React.createRef();
    this.paypalValButton = React.createRef();
  }

  componentWillMount() {
    

    window.addEventListener('scroll', (e) => {
      this._scroller.current.style.backgroundColor = "rgba(47,85,127,"+(1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) )+")";
       this._scrollerLogo.current.style.opacity = (1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) );
    });
  }

  componentDidMount() { 

    getDonationTypes(this.state.locale).then(response => {
      this.setState({ donationTypes: response.data });
    });

    getBanner(this.state.locale,3).then(response => {
      let data = (response.data.length > 0) ? response.data[0].img : ""
      this.setState({ banner: data });

    });

  }


  render() {
    
    return (
      <div className="donationContainer">

          <Header />
        
          <div className="bodyContent">
             <div className="banner" style={{ "backgroundImage": "url(" + RES_BASE_URL + "storage/" + this.state.banner + ")" }}>
               <div className="wrapper">
                 <h1>{getValue("donation")}</h1>
               </div>
             </div>                                                

             <div className="subCatBanner">
                <a>{getValue('home')}</a>
             </div>

             <div className="wrapper">
              <h2>{getValue("donationCompleteMessage")}</h2>
              {
                  (this.state.locale == "tc") ? (

                    <div>

                      <div className="otherDonationMethod">
                        <p>其他奉献方法：</p>

                        <p>劃線支票 <br />
                        劃線支票抬頭請寫上「華安聯合會有限公司」或「Chinese Union Mission Of Seventh-day Adventists Limited」
                        請在支票背後著明「奉獻項目名稱」「奉獻者姓名」「聯絡電話」
                        信封背面請寫上「回郵地址」</p>
                         
                        <p>郵寄往： <br />
                        華安聯合會 沙田小瀝源，源順圍28號，都會廣場12樓 </p>

                        <p>＊奉獻金額港幣100元或以上，憑正式收據可於所得稅中免稅</p>

                      </div>

                    </div>

                  ) : (

                    (this.state.locale == "sc") ? (

                      <div>

                          <div className="otherDonationMethod">
                            <p>其他奉献方法：</p>

                            <p>划线支票 <br />
                            划线支票抬头请写上「华安联合会有限公司」或「Chinese Union Mission Of Seventh-day Adventists Limited」
                            请在支票背后着明「奉献项目名称」「奉献者姓名」「联络电话」
                            信封背面请写上「回邮地址」</p>
                             
                            <p>邮寄往： <br />
                            华安联合会 沙田小沥源，源顺围28号，都会广场12楼 </p>

                            <p>＊奉献金额港币100元或以上，凭正式收据可于所得税中免税</p>

                          </div>

                        </div>

                    ) : (

                      <div>

                          <div className="otherDonationMethod">

                            <p>You may also make a donation by cheque: <br/>
                            Make cheque payable to "Chinese Union Mission Of Seventh-day Adventists Limited"
                            Please provide your name, project , contact phone number and return mailing address for receipt.
                            </p>
                             
                            <p>Mail To：<br />
                            Chinese Union Mission<br/>
                            12/F., Citimark, 28 Yuen Shun Circuit,<br/>
                            Siu Lek Yuen, Shatin, N.T., Hong Kong</p>

                            <p>＊ Tax deductible receipt (only for Hongkong Citizien).</p>

                          </div>

                        </div>

                      )

                  )
                 }
             </div>

             </div>

          <div ref={this._scroller} className="sideBar">
            <img className="logo blueLogo" src={blueLogo} />
            <img ref={this._scrollerLogo} className="logo" src={logo} />
          </div>


        <Footer />
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {

  };
}

export default withRouter(connect(
    mapStateToProps,
    { }
)(DonationComplete));
