import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { getLanding, postNewsByWord, RES_BASE_URL } from '../../redux/action';

import './Landing.css'; 
import Header from '../common/Header';
import Footer from '../common/Footer';
import logo from '../../images/whitelogo.png';
import blueLogo from '../../images/logo-blue.png';
import videoSample from '../../images/videoThumbNailSample.png';
import newsSample from '../../images/newsThumbNailSample.png';


// import logo from '../../images/logo.png';

import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";

import BannerAnim from 'rc-banner-anim';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import 'rc-banner-anim/assets/index.css';

const { Element } = BannerAnim;
const BgElement = Element.BgElement;

class Landing extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      selectedCategoryId: -1,
      activeCategory: 0,
      isScrolling: false,
      isCollecting: false,
      landingData: {},
      scrollerOpacity: 1,
    };
    this._scroller = React.createRef();
    this._scrollerLogo = React.createRef();
  }

  componentWillMount() {
    getLanding(this.state.locale).then(response => {
      console.log(response.data);
      this.setState({ landingData: response.data });
    });

    window.addEventListener('scroll', (e) => {
      this._scroller.current.style.backgroundColor = "rgba(47,85,127,"+(1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) )+")";
       this._scrollerLogo.current.style.opacity = (1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) );
    });
  }

  componentDidMount() { 
  }

  htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  filterNewsByTags(tag) {

    postNewsByWord({ "cat": 0, "locale": this.state.locale, "word": "", "keyword": "", "tag": tag }).then(response => {
      this.setState({ newsList: response.data.tbl_news, newsTags:  response.data.tbl_tags });
    });

  }

  banners() {
    return (this.state.landingData.tbl_banner || []).map(tbl_banner => {
       return (
         <Element key="aaa"
            prefixCls="banner-user-elem"
          >
            <BgElement
              key="bg"
              className="bg"
              style={{
                backgroundImage: 'url(' + RES_BASE_URL + '/storage/' + tbl_banner.img +')',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                // height: '500px'
              }}
              onClick={() => window.location.href = tbl_banner.link }
            />
          </Element>
        );
    });
  }

  render() {
    console.log(this.state.landingData.tbl_banner);
    return (

      <div className="landingContainer">

        <Header />
        <div className="homeBanner">
          <BannerAnim
          autoPlay
          autoPlaySpeed={3000}
        >
          
          { this.banners() }
        </BannerAnim>
          
        </div>
        <div className="bodyContent">
          <div className="wrapper">
             <div className="content">
               <div className="section">
                 <div className="sectionHeader">
                   <div className="indicator"></div>
                   <h3>{getValue("what_news")}</h3>
                 </div>

                 <div className="filterTagDiv">
                  {
                    (this.state.landingData.tbl_tags || []).map(tbl_tag => {
                      return (tbl_tag.is_filter == 1) ? <span className="filterTag" onClick={() => (window.location.href = "/news/0/"+tbl_tag.tag_name) }>{"#" + tbl_tag.tag_name}</span> : ""
                    })
                  }
                </div>

                 <div className="newsListing">
                   {
                       (this.state.landingData.tbl_news || []).map(item => {
                        var tags = [];
                        if (item.tags) {
                          tags = item.tags.split(",");
                        }
                        return (
                          <div className="newsGroup">
                           <div>
                             <img src={RES_BASE_URL+"storage/"+item.img_1} onClick={() => (window.location.href = "/departmentals/news/"+item.comm_id+"/"+item.id)} />
                             <p>{getValue("last_update_on")} {item.postDate}</p>
                           </div>
                           
                           <div>
                             <h4 onClick={() => (window.location.href = "/departmentals/news/"+item.comm_id+"/"+item.id)}>{item.title}</h4>
                             <h5>{item.intro}</h5>
                             <div className="tagDiv">
                              {
                                (tags).map(tag => {
                                  return <span>
                                    {
                                      (this.state.landingData.tbl_tags || []).map(tbl_tag => {
                                        return (tbl_tag.id == tag) ? <span className="tagSpan" onClick={() => (window.location.href = "/news/0/"+tbl_tag.tag_name) }>{"#" + tbl_tag.tag_name}</span> : ""
                                      })
                                    }
                                  </span>
                                }, this)
                              }
                             </div>
                           </div>

                          </div>
                        );
                       })                  
                    }
                   </div>
               </div>

               <div className="section">
                 <div className="sectionHeader">
                   <div className="indicator"></div>
                   <h3>{getValue("news_video")}</h3>
                 </div>
                 <div className="videoListing">

                  {
                    (this.state.landingData.tbl_videos || []).map(item => {
                      return (
                        <div className="videoGroup">
                         <div className="videoFrameContent" dangerouslySetInnerHTML={{ __html: (item.detail) }} ></div>
                         <p>
                           {item.title}
                         </p>
                        </div>
                      );
                    })
                  }

                 </div>
                


               </div>
               
             </div>
             
          </div>

        </div>
        
        <div ref={this._scroller} className="sideBar">
          <img className="logo blueLogo" src={blueLogo} />
          <img ref={this._scrollerLogo} className="logo" src={logo} />
        </div>

        <Footer />
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {

  };
}

export default withRouter(connect(
    mapStateToProps,
    {  }
)(Landing));
