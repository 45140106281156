import axios from 'axios';

export const CMS_BASE_URL = "https://api.chumsda.org/";// http://sda-web-api.demo-uat.com/"; //'http://localhost:8000';//
export const RES_BASE_URL = "https://api.chumsda.org/";//'http://sda-web-api.demo-uat.com/';
export const APP_DOMAIN = "https://new.chumsda.org/";//"http://sdaweb.usoplanet.com/";
export const PROD_BASE_URL = "https://new.chumsda.org";//"http://sdaweb.usoplanet.com";

// export const CMS_BASE_URL = "http://sda-web-api.demo-uat.com/"; //'http://localhost:8000';//
// export const RES_BASE_URL = 'http://sda-web-api.demo-uat.com/';
// export const APP_DOMAIN = "http://sdaweb.usoplanet.com/";
// export const PROD_BASE_URL = "http://sdaweb.usoplanet.com";


export function getLanding(locale) {
  const request = axios.get(CMS_BASE_URL+'/getLanding/'+locale);
  return request;
}

export function getNewsCat(locale) {
  const request = axios.get(CMS_BASE_URL+'/getNewsCategory/'+locale);
  return request;
}

export function getNewsByCat(locale, cat_id) {
  const request = axios.get(CMS_BASE_URL+'/getNews/'+locale+"/"+cat_id);
  return request;
}

export function postNewsByWord(body) {
  const request = axios.post(CMS_BASE_URL+'/postNewsByWord',body);
  return request;
}

export function getNewsById(locale, id) {
  const request = axios.get(CMS_BASE_URL+'/getNewsById/'+locale+"/"+id);
  return request;
}

export function getCompanyInfo(locale) {
  const request = axios.get(CMS_BASE_URL+'/getCompanyInfo/'+locale);
  return request;
}

export function getComm(locale) {
  const request = axios.get(CMS_BASE_URL+'/getComm/'+locale);
  return request;
}

export function getLastCall(locale) {
  const request = axios.get(CMS_BASE_URL+'/getLastCall/'+locale);
  return request;
}

export function getFaithAndYou(locale) {
  const request = axios.get(CMS_BASE_URL+'/getFaithAndYou/'+locale);
  return request;
}

export function getCommIntro(locale, cid) {
  const request = axios.get(CMS_BASE_URL+'/getCommIntro/'+locale+"/"+cid);
  return request;
}

export function fileSize(body) {
  const request = axios.post(CMS_BASE_URL+'/fileSize',body);
  return request;
}

export function getCommNews(locale, cid) {
  const request = axios.get(CMS_BASE_URL+'/getCommNews/'+locale+"/"+cid);
  return request;
}

export function getDonationTypes(locale) {
  const request = axios.get(CMS_BASE_URL+'/getDonationTypes/'+locale);
  return request;
}

export function postPaypal($body) {
  const request = axios.post(CMS_BASE_URL+'/postPaypal', $body );
  return request;
}

export function capturePaypal($body) {
  const request = axios.post(CMS_BASE_URL+'/captureOrder', $body );
  return request;
}

export function search(body) {
  const request = axios.post(CMS_BASE_URL+'/search', body);
  return request;
}

export function getBanner(locale, tag) {
  const request = axios.get(CMS_BASE_URL+'/getBanner/'+locale+"/"+tag);
  return request;
}

export function getDynamicContact(locale) {
  const request = axios.get(CMS_BASE_URL+'/getDynamicContact/'+locale);
  return request;
}

