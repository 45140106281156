import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import logo from '../../images/logo.png';
import logoChi from '../../images/logoChi.png';
// import MenuIcon from '@material-ui/icons/Menu';
import { getValue } from "../../constant/Locale";

import './Footer.css'; 

class Footer extends Component {

	constructor(props, context) {
	    super(props, context);
	    this.state = {
	    };
	  }

	componentDidMount() {

	}

	

	render() {
		
		return (
		<div className="footer">
			<div className="wrapper">
				<div>
					<ul className="bottomMenu">
						<a href="/contact">{getValue("about_us")}</a>
						<a href="/news">{getValue("what_news")}</a>
						<a href="https://www.sdabible.org/" target="_blank">{getValue("share_resources")}</a>
						<a href="/departmentals">{getValue("committee")}</a>
						<a href="/donation">{getValue("donation")}</a>
					</ul>
					<p><span>{getValue('base_title')}</span> Chinese Union Mission &copy; 2021 • ALL RIGHTS RESERVED • <a target="_blank" href="https://www.chumadventist.org/privacy_policy.html">Privacy Policy</a></p>
				</div>
				<div>
					<script async src="https://cse.google.com/cse.js?cx=4f8d9071c83769475"></script>
					<div className="gcse-searchbox-only"></div>
				</div>
			</div>
	    </div>

		);
	}

}

const mapStateToProps = (state) => {
  return {
    // error: getMembershipError(state.membershipReducer),
    // pending: getMembershipPending(state.membershipReducer),
    // registerSuccess: getMembershipRegisterStatus(state.membershipReducer),
    // userInfo: getMembershipUserInfo(state.membershipReducer),
    // changePwSuccess:  getChangePasswordSuccess(state.membershipReducer),
    // verifyEmailSuccess: getVerifyEmailSuccess(state.membershipReducer)
    // content_verison: getBibleContentVersion(state.bibleBookReducer),
    // secondary_content_verison: getBibleSecondaryContentVersion(state.bibleBookReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  // register: registerAction,
  // login: loginAction,
  // logout: logoutAction,
  // changePw: changePwAction,
  // changeInfo: changeInfoAction,
  // sendForgetPwEmail: sendForgetPwEmailAction,
  // verifyEmail: verifyEmailAction
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer));