import React, { Component } from 'react';

const localeJson = 
{
	"tc": {
		"base_title": "華安聯合會",
		"read_more": "閱讀更多",
		"about_us": "關於我們",
		"what_news": "最新消息",
		"news_video": "最新視像",
		"faith_and_you": "信仰與你",
		"last_call": "末世牧聲",
		"share_resources": "共享資源",
		"committee": "部門事工",
		"committee_reousrce": "事工與資源",
		"donation": "奉獻",
		"traditional_chi": "繁體",
		"simpified_chi": "简体",
		"friendly_links": "友好鏈結",
		"chineseLogoLine1": "基督復臨安息日會",
		"chineseLogoLine2": "華安聯合會",
		"intro": "簡介",
		"resources": "資源",
		"mission": "使命宣言",
		"ourMission": "華安聯合會使命宣言",
		"basicBeliefs": "基本信仰",
		"chineseHistory": "中華聖工史",
		"organization": "組織架構",
		"churchPosition": "教會立場",
		"contactUs": "聯絡我們",
		"pubicTransport": "交通導引",
		"ourAddress": "華安聯合會地址",
		"allNews": "全部消息",
        "departmentNews": "部門消息",
        "committeeNews": "區會消息",                                                        
        "overseaNews": "海外消息",                                                        
        "newsInfo": "最新視訊",                                                        
        "covidTopic": "抗疫專題", 
        "overview": "簡介",
        "resources": "資源",
        "donation": "奉獻",
        "donorInfo": "奉獻者資料",
        "donationMsg": "奉獻信息",
        "mission_1_headline_1": "我們的方法",
        "mission_1_headline_2": "我們的異像",
        "mission_1_1": "基督复臨安息日會華安聯合會的使命就是拓展上帝的國度。\n\n 華安呼籲所有的人成為耶穌基督的門徒、宣揚三天使信息（啟14：6-12）中所含的永遠的福音、預備住在本地區的民眾迎接基督的早日復臨。",
		"mission_1_2": "在聖經和聖靈的指引下，華安聯合會將運用上帝所賜的方法，通過致力於教會生活的三個核心方面，在靈性上、功能上以及信徒人數上取得增長，從而達成上述使命。",
		"mission_1_3": "與聖經中的啟示相和諧，复臨信徒認為上帝救贖計劃的高潮就是上帝將祂的一切創造恢復至與祂的完美的旨意和公義相符的狀態。",
		"mission_1_4": "2019年2月更新版本",
		"filter": "篩選",
		"keyword": "關鍵字",
		"tag": "標籤",
		"donation_title": "稱謂：",
		"donation_sir": "先生",
		"donation_mad": "女士",
		"donation_ch_name": "中文姓名（如適用）",
		"donation_en_name": "英文姓名",
		"donation_day_tel": "日間聯絡電話：",
		"donation_email": "電郵地址：",
		"donation_mail_add": "郵政地址：",
		"donation_amt": "奉獻金額：",
		"donation_type": "奉獻項目：",
		"donation_training_center": "培訓中心",
		"donation_health_center": "健康教育中心",
		"donation_last_call": "末世牧聲",
		"donation_other": "其他",
		"donation_receipt_arr": "收據安排：",
		"donation_receipt_headline": "請郵寄收據，收據抬頭名稱 ：",
		"donation_receipt_addr": "郵寄收據地址：",
		"donation_no_receipt": "謝絕收據",
		"donation_captcha": "驗證碼：",
		"donation_err_captcha": "請輸入驗證碼",
		"donation_err_type": "請選擇奉獻項目",
		"donation_err_amt": "請輸入奉獻金額",
		"donation_err_email": "請輸入有效的電郵地址",
		"donation_err_tel": "請輸入日間聯絡電話",
		"donation_err_name": "請輸入英文姓名",
		"back_btn": "返回",
		"doc_date": "日期",
		"doc_title": "內容",
		"doc_size": "檔案大小",
		"search": "搜尋",
		"search_no_result": "沒有相關結果",
		"committee_news_tag_title": "動態",
		"grid_view": "網格",
		"list_view": "列表",
		"last_update_on": "更新時間：",
		"donationCompleteMessage": "非常感謝您的奉獻",
	},
	"sc": {
		"base_title": "华安联合会",
		"read_more": "阅读更多",
		"about_us": "关于我们",
		"what_news": "最新消息",
		"news_video": "最新视像",
		"faith_and_you": "信仰与你",
		"last_call": "末世牧声",
		"share_resources": "共享资源",
		"committee": "部门事工",
		"committee_reousrce": "事工与资源",
		"donation": "奉献",
		"traditional_chi": "繁體",
		"simpified_chi": "简体",
		"friendly_links": "友好链结",
		"chineseLogoLine1": "基督复临安息日会",
		"chineseLogoLine2": "华安联合会",
		"intro": "简介",
		"resources": "资源",
		"mission": "使命宣言",
		"ourMission": "华安联合会使命宣言",
		"basicBeliefs": "基本信仰",
		"chineseHistory": "中华圣工史",
		"organization": "组织架构",
		"churchPosition": "教会立场",
		"contactUs": "联络我们",
		"pubicTransport": "交通导引",
		"ourAddress": "华安联合会地址",
		"allNews": "全部消息",
        "departmentNews": "部门消息",
        "committeeNews": "区会消息",                                                        
        "overseaNews": "海外消息",                                                        
        "newsInfo": "最新视讯",                                                        
        "covidTopic": "抗疫专题", 
        "overview": "简介",
        "resources": "资源",
        "donation": "奉献",
        "donorInfo": "奉献者资料",
        "donationMsg": "奉献信息",
        "mission_1_headline_1": "我们的方法",
        "mission_1_headline_2": "我们的异像",
        "mission_1_1": "基督复临安息日会 华安联合会的使命就是拓展上帝的国度。\n\n 华安呼吁所有的人成为耶稣基督的门徒、宣扬三天使信息（启14：6-12）中所含的永远的福音、预备住在本地区的民众迎接基督的早日复临。",
		"mission_1_2": "在圣经和圣灵的指引下，华安联合会将运用上帝所赐的方法，通过致力于教会生活的三个核心方面，在灵性上、功能上以及信徒人数上取得增长，从而达成上述使命。",
		"mission_1_3": "与圣经中的启示相和谐，复临信徒认为上帝救赎计划的高潮就是上帝将祂的一切创造恢复至与祂的完美的旨意和公义相符的状态。",
		"mission_1_4": "2019年2月更新版本",
		"filter": "筛选",
		"keyword": "关键字",
		"tag": "标签",
		"donation_title": "称谓：",
		"donation_sir": "先生",
		"donation_mad": "女士",
		"donation_ch_name": "中文姓名（如适用）",
		"donation_en_name": "英文姓名",
		"donation_day_tel": "日间联络电话：",
		"donation_email": "电邮地址：",
		"donation_mail_add": "邮政地址：",
		"donation_amt": "奉献金额：",
		"donation_type": "奉献项目：",
		"donation_training_center": "培训中心",
		"donation_health_center": "健康教育中心",
		"donation_last_call": "末世牧声",
		"donation_other": "其他",
		"donation_receipt_arr": "收据安排：",
		"donation_receipt_headline": "请邮寄收据，收据抬头名称 ：",
		"donation_receipt_addr": "邮寄收据地址：",
		"donation_no_receipt": "谢绝收据",
		"donation_captcha": "验证码：",
		"donation_err_captcha": "请输入验证码",
		"donation_err_type": "请选择奉献项目",
		"donation_err_amt": "请输入奉献金额",
		"donation_err_email": "请输入有效的电邮地址",
		"donation_err_tel": "请输入日间联络电话",
		"donation_err_name": "请输入英文姓名",
		"back_btn": "返回",
		"doc_date": "日期",
		"doc_title": "內容",
		"doc_size": "档案大小",
		"search": "搜寻",
		"search_no_result": "没有相关结果",
		"committee_news_tag_title": "动态",
		"grid_view": "网格",
		"list_view": "列表",
		"last_update_on": "更新时间：",
		"donationCompleteMessage": "非常感谢您的奉献",
	},
	"en": {
		"base_title": "Chinese Union Mission",
		"read_more": "Read more",
		"about_us": "About Us",
		"what_news": "Latest News",
		"news_video": "Latest video",
		"faith_and_you": "Faith and You",
		"last_call": "LDSC",
		"share_resources": "Shared Resources",
		"committee": "Resources",
		"committee_reousrce": "Resource",
		"donation": "Donation",
		"traditional_chi": "繁體",
		"simpified_chi": "简体",
		"friendly_links": "friendly_links",
		"chineseLogoLine1": "Chinese Union Mission",
		"chineseLogoLine2": "",
		"intro": "Introduction",
		"resources": "Resources",
		"mission": "Mission Statement",
		"ourMission": "Mission Statement",
		"basicBeliefs": "Fundamental Beliefs",
		"chineseHistory": "History of Chinese Work",
		"organization": "Organization",
		"churchPosition": "Position",
		"contactUs": "Contact Us",
		"pubicTransport": "Traffic Guide",
		"ourAddress": "Chinese Union Mission Address",
		"allNews": "All News",
        "departmentNews": "Department News",
        "committeeNews": "committee news",
        "overseaNews": "Oversea News",
        "newsInfo": "Latest Video",
        "covidTopic": "Anti-epidemic Topic",
        "overview": "Introduction",
        "resources": "Resources",
        "donation": "Donation",
        "donorInfo": "Donor Info",
        "donationMsg": "Donation Information",
        "mission_1_headline_1": "Our Way",
        "mission_1_headline_2": "Our Vision",
        "mission_1_1": "The mission of the Seventh-day Adventist Chinese Union Mission is to expand the kingdom of God.\n\n Chinese Union Mission calls on all people to become disciples of Jesus Christ and preach the message of the three angels (Revelation 14:6-12) Ham’s eternal gospel, ready for the people living in this area to welcome Christ’s early return.",
		"mission_1_2": "Under the guidance of the Bible and the Holy Spirit, the Chinese Union Mission will use God-given methods to achieve growth in spirituality, function, and the number of believers by devoting to the three core aspects of church life. The above mission.",
		"mission_1_3": "In harmony with the revelation in the Bible, Adventists believe that the climax of God's redemption plan is that God restores all his creations to a state consistent with his perfect will and justice.",
		"mission_1_4": "Updated version in February 2019",
		"filter": "Filter",
		"keyword": "Keyword",
		"tag": "Tag",
		"donation_title": "Title:",
		"donation_sir": "Mr.",
		"donation_mad": "Ms.",
		"donation_ch_name": "Chinese name (if applicable)",
		"donation_en_name": "English name",
		"donation_day_tel": "Daytime contact number:",
		"donation_email": "Email address:",
		"donation_mail_add": "Postal address:",
		"donation_amt": "Donation amount:",
		"donation_type": "Donation Project:",
		"donation_training_center": "Training Center",
		"donation_health_center": "Health Education Center",
		"donation_last_call": "LDSC",
		"donation_other": "Other",
		"donation_receipt_arr": "Receipt arrangement:",
		"donation_receipt_headline": "Please mail the receipt, the name of the receipt should be:",
		"donation_receipt_addr": "Mail receipt address:",
		"donation_no_receipt": "Receipt declined",
		"donation_captcha": "Verification Code:",
		"donation_err_captcha": "Please enter the verification code",
		"donation_err_type": "Please select a donation item",
		"donation_err_amt": "Please enter the donation amount",
		"donation_err_email": "Please enter a valid email address",
		"donation_err_tel": "Please enter the daytime contact number",
		"donation_err_name": "Please enter your English name",
		"back_btn": "Back",
		"doc_date": "Date",
		"doc_title": "Content",
		"doc_size": "File Size",
		"search": "Search",
		"search_no_result": "No Result",
		"committee_news_tag_title": "Dynamic",
		"grid_view": "Grid",
		"list_view": "List",
		"last_update_on": "Last Updated on",
		"donationCompleteMessage": "Thank you so much for your donation",
	},
};

export const getLocalPlanNameKey = () => {
	return (localStorage.getItem('locale') == 'tc') ? "name_tc" : "name_sc";
}

export const getLocalPlanDescKey = () => {
	return (localStorage.getItem('locale') == 'tc') ? "desc_tc" : "desc_sc";
}

export const getLocalLocale = () => {
	return localStorage.getItem('locale') || 'tc';
}

export const getValue = (key) => {
	const locale = localStorage.getItem('locale') || 'tc';
	
	// console.log(locale);
	return localeJson[locale][key];
}