import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { getNewsCat,getBanner,getNewsById, RES_BASE_URL } from '../../redux/action';

import './news.css'; 
import Header from '../common/Header';
import Footer from '../common/Footer';
import logo from '../../images/whitelogo.png';
import wechat from '../../images/wechat.png';
import blueLogo from '../../images/logo-blue.png';
import videoSample from '../../images/videoThumbNailSample.png';
import newsSample from '../../images/newsThumbNailSample.png';
import newsSample2 from '../../images/newsThumbnailSample2.jpg';

import SearchIcon from '@material-ui/icons/Search';

import QRcode from 'qrcode.react';

import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  WeiboShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";


// import logo from '../../images/logo.png';

import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";

class NewsDetails extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      selectedCategoryId: -1,
      activeCategory: 0,
      isScrolling: false,
      isCollecting: false,
      focusIndex: 0,
      activeCategoryId: 0,
      searchWord: "",
      newsCategory: [{"id":0, "title": getValue("allNews")}],
      news: {},
    };
    this._scroller = React.createRef();
    this._scrollerLogo = React.createRef();
  }

  componentWillMount() {

    getNewsCat(this.state.locale).then(response => {
      this.setState({ newsCategory: [...this.state.newsCategory, ...response.data] });
      getNewsById(this.state.locale, this.props.match.params.id).then(res => {
        this.setState({ news: res.data });
      }, this)
    });

    window.addEventListener('scroll', (e) => {
      this._scroller.current.style.backgroundColor = "rgba(47,85,127,"+(1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) )+")";
       this._scrollerLogo.current.style.opacity = (1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) );
    });

  }

  htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  componentDidMount() { 

    getBanner(this.state.locale,2).then(response => {
      let data = (response.data.length > 0) ? response.data[0].img : ""
      this.setState({ banner: data });

    });

  }

  swapSubMenu(idx, id) {
    window.location.href = "/news";
  }


  render() {

    const shareUrl = window.location.href;
    const shareTitle = "";
    
    return (
      <div className="newsContainer">

          <Header />
        
          <div className="bodyContent">
             <div className="banner" style={{ "backgroundImage": "url(" + RES_BASE_URL + "storage/" + this.state.banner + ")" }}>
               <div className="wrapper">
                 <h1>{(this.state.news || {}).title}</h1>
               </div>
             </div>                                                

             <div className="subCatBanner">
              <div className="wrapper">
                 
               </div>
             </div>

             <div className="wrapper">
                 <div className="newsArea">
                   <div className="newsListing">
                     
                       <div className="innerWrapper">

                         <div dangerouslySetInnerHTML={{ __html: ((this.state.news || {}).detail) }} ></div>
             
                       </div>
                    
                    </div>

                    <div className="searchBox">
                      
                      <FacebookShareButton
                        url={shareUrl}
                        quote={shareTitle}
                        className="Demo__some-network__share-button"
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>

                      <WhatsappShareButton
                        url={shareUrl}
                        title={shareTitle}
                        separator=":: "
                        className="Demo__some-network__share-button"
                      >
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>

                      <LineShareButton
                        url={shareUrl}
                        title={shareTitle}
                        className="Demo__some-network__share-button"
                      >
                        <LineIcon size={32} round />
                      </LineShareButton>

                      <WeiboShareButton
                        url={shareUrl}
                        title={shareTitle}
                        image={`""`}
                        className="Demo__some-network__share-button"
                      >
                        <WeiboIcon size={32} round />
                      </WeiboShareButton>

                      <div className="wechatIcon">

                        <img src={wechat} />

                        <div className="wechatQrcode">
                          <QRcode value={shareUrl} />
                        </div>

                      </div>
                         
                    </div>
               </div>

             </div>
             
          </div>
          <div ref={this._scroller} className="sideBar">
            <img className="logo blueLogo" src={blueLogo} />
            <img ref={this._scrollerLogo} className="logo" src={logo} />
          </div>


        <Footer />
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {

  };
}

export default withRouter(connect(
    mapStateToProps,
    {  }
)(NewsDetails));
