import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getDynamicContact, getBanner, RES_BASE_URL } from '../../redux/action';

import './Contact.css'; 
import Header from '../common/Header';
import Footer from '../common/Footer';
import logo from '../../images/whitelogo.png';
import blueLogo from '../../images/logo-blue.png';
import videoSample from '../../images/videoThumbNailSample.png';
import newsSample from '../../images/newsThumbNailSample.png';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';


// import logo from '../../images/logo.png';

import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    'position': 'absolute',
    'right': '0'
  },
  svg: {
    'color': 'white'
  },
  input: {
    iframeWidth: 500,
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    outline: 'none',
    // border: '1px solid #ced4da',
    fontSize: 16,
    color: 'white',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      // borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

class Contact extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      dynamicContacts: [],
      selectedCategoryId: -1,
      activeCategory: 0,
      isScrolling: false,
      isCollecting: false,
      focusIndex: this.props.match.params.tab || 0,
      circle: 'testing'
    };
    this._scroller = React.createRef();
    this._scrollerLogo = React.createRef();
  }

  componentWillMount() {
    

    window.addEventListener('scroll', (e) => {
      this._scroller.current.style.backgroundColor = "rgba(47,85,127,"+(1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) )+")";
       this._scrollerLogo.current.style.opacity = (1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) );
    });

    if (window.innerWidth <= 800) {
        this.setState({ iframeWidth: 250 });
      } else {
        this.setState({ iframeWidth: 500 });
      }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 800) {
        this.setState({ iframeWidth: 250 });
      } else {
        this.setState({ iframeWidth: 500 });
      }
    });
  }

  componentDidMount() { 

    getBanner(this.state.locale,1).then(response => {
      let data = (response.data.length > 0) ? response.data[0].img : ""
      this.setState({ banner: data });

    });

    getDynamicContact(this.state.locale).then(response => {
      this.setState({ dynamicContacts: response.data });

    });

  }

  swapSubMenu(index) {
    this.setState({ focusIndex: index });
  }

  getTitle() {
    return (this.state.dynamicContacts.length > (this.state.focusIndex)) ? this.state.dynamicContacts[this.state.focusIndex].title : "";
  }

  handleChange(event) {
    this.setState({ focusIndex: event.target.value});
  }  

  render() {
    
    return (
      <div className="contactContainer">

          <Header />
        
          <div className="bodyContent">
             <div className="banner" style={{ "backgroundImage": "url(" + RES_BASE_URL + "storage/" + this.state.banner + ")" }}>
               <div className="wrapper">
                 <h1>{this.getTitle()}</h1>
               </div>
             </div>

             <div className="subCatBanner">
              <div className="wrapper">
                 <ul className="subCatMenu">
                    {
                      (this.state.dynamicContacts || []).map((item,idx) => {
                        return <li onClick={() => this.swapSubMenu(idx)}>{item.title}</li>
                      })
                    }
                 </ul>
                 <FormControl className="mobileDropdown">
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      onChange={(event) => this.handleChange(event)}
                      input={<BootstrapInput />}
                      defaultvalue={this.state.circle}
                    >
                      {
                        (this.state.dynamicContacts || []).map((item,idx) => {
                          return <MenuItem value={idx}>{item.title}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
               </div>
             </div>

             {
                (this.state.dynamicContacts || []).map((item,idx) => {

                    return (

                     <div className={(this.state.focusIndex == (idx) )? "ourMission activeSubContent" : "ourMission subContent"}>
                        <div className="wrapper">
                          <div dangerouslySetInnerHTML={{ __html: item.detail }} ></div>
                        </div>
                     </div>

                    )
                
                })
             }

          </div>
          <div ref={this._scroller} className="sideBar">
            <img className="logo blueLogo" src={blueLogo} />
            <img ref={this._scrollerLogo} className="logo" src={logo} />
          </div>


        <Footer />
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {
      // error: getLandingResourceError(state.landingResourceReducer),
      // res: getLandingResource(state.landingResourceReducer),
      // pending: getLandingResourcePending(state.landingResourceReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    // getLandingResource: getLandingResourceAction,
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Contact));
