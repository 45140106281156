import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import ROUTES, { RenderRoutes } from "./routes/routes";

import { BrowserRouter as BrowserRouter, Route, Redirect} from 'react-router-dom';
import ReactGA from 'react-ga';
import Analytics from 'react-router-ga';

const TRACKING_ID = "UA-59007761-2"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {

  return (
	  <BrowserRouter>
	  	 <Analytics id="UA-59007761-2" debug>
	    <div>
		    <RenderRoutes routes={ROUTES} />
	    </div>
	    </Analytics>
	  </BrowserRouter>
  );
}

export default App;
