import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { postPaypal, capturePaypal, getDonationTypes, getBanner, RES_BASE_URL } from '../../redux/action';

import './Donation.css'; 
import './DonationError.css'; 
import Header from '../common/Header';
import Footer from '../common/Footer';
import logo from '../../images/whitelogo.png';
import blueLogo from '../../images/logo-blue.png';
import leftArrow from '../../images/leftArrow.png';
import rightArrow from '../../images/rightArrow.png';
import videoSample from '../../images/videoThumbNailSample.png';
import newsSample from '../../images/newsThumbNailSample.png';
import paypalBtn from '../../images/paypalBtn.png';

import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import logo from '../../images/logo.png';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";
import Captcha from "demos-react-captcha";

class Donation extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      donationTypes: [],
      selectedCategoryId: -1,
      activeCategory: 0,
      isScrolling: false,
      isCollecting: false,
      focusIndex: 0,
      currentIndex: 0,
      title: "",
      chineseName: "",
      firstName: "",
      lastName: "",
      tel: "",
      address: "",
      email: "",
      receipt: "",
      receiptTitle: "",
      receiptAddress: "",
      amt: 0,
      donateType: "",
      donateTypeOther: "",
      donateDate: "",
      paypalCode: "",
      paymentSubmission: true,
      activeButton: false,
      displayValidateForm: false,
      recapcha: false,
    };
    this._scroller = React.createRef();
    this._scrollerLogo = React.createRef();
    this.paypalValButton = React.createRef();
  }

  componentWillMount() {
    

    window.addEventListener('scroll', (e) => {
      this._scroller.current.style.backgroundColor = "rgba(47,85,127,"+(1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) )+")";
       this._scrollerLogo.current.style.opacity = (1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) );
    });
  }

  componentDidMount() { 

    getDonationTypes(this.state.locale).then(response => {
      this.setState({ donationTypes: response.data });
    });

    getBanner(this.state.locale,3).then(response => {
      let data = (response.data.length > 0) ? response.data[0].img : ""
      this.setState({ banner: data });

    });

  }

  onChange(value) {
    console.log(value);
    if (value) {
      this.setState({ recapcha: value });
    }
  }

  bindPaypalComplete(data,action) {
    
    return capturePaypal({ "orderId": data.orderID }).then(function(res) {
      console.log(res);
      return res.data;
    }).then(function(data) {
      window.location.href = "/donationComplete";
      // return data;
    });

  }

  bindPaypalCreate() {

    let flag = this.validateFields();
    this.setState({ paymentSubmission: flag });
    if (!flag) {
      return -1;
    }

    return postPaypal(this.paymentObject()).then(function(res) {
      return res.data.result;
    }).then(function(data) {
      return data.id;
    });
  }

  validateFields() {
    if (this.state.firstName.length === "" || this.state.lastName.length === "" || this.state.email.length === "" || this.state.amt.length === "" || this.state.donateType.length === "" || !this.state.recapcha) {
      this.setState({ activeButton: false });
      return false;
    }

    this.setState({ activeButton: true });
    return true;
  }

  validateFieldsCaptcha(captcha) {
    if (this.state.firstName.length === "" || this.state.lastName.length === "" || this.state.email.length === "" || this.state.amt.length === "" || this.state.donateType.length === "" || !captcha) {
      this.setState({ activeButton: false });
      return false;
    }

    this.setState({ activeButton: true });
    return true;
  }

  paymentObject() {

    return {
      "total": this.state.amt,
      "title": this.state.title,
      "chineseName": this.state.chineseName,
      "firstName": this.state.firstName,
      "lastName": this.state.lastName,
      "tel": this.state.tel,
      "address": this.state.address,
      "email": this.state.email,
      "receipt": this.state.receipt,
      "receiptTitle": this.state.receiptTitle,
      "total": this.state.amt,
      "amt": this.state.amt,
      "donateType": this.state.donateType,
      "donateTypeOther": this.state.donateTypeOther,
      "donateDate": this.state.donateDate,
      "paypalCode": this.state.paypalCode
    }
  }

  render() {
    
    return (
      <div className="donationContainer">

          <Header />
        
          <div className="bodyContent">
             <div className="banner" style={{ "backgroundImage": "url(" + RES_BASE_URL + "storage/" + this.state.banner + ")" }}>
               <div className="wrapper">
                 <h1>{getValue("donation")}</h1>
               </div>
             </div>                                                

             <div className="subCatBanner">
              
             </div>

             <div className="wrapper">


                 <div className="container">
                     <div className="leftContainer">

                       <div className="sectionHeader">
                         <div className="indicator"></div>
                         <h3>{getValue("donorInfo")}</h3>
                       </div>

                       <div className="inputRows">
                         <label>{getValue("donation_title")}</label>
                         <div className=" radios">
                         <div className="radioGroup" onClick={(e) => this.setState({ title: "M"})}>
                           {
                              (this.state.title === "M") ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />
                           }
                           <label>{getValue("donation_sir")}</label>
                         </div>
                         <div className="radioGroup" onClick={(e) => this.setState({ title: "F"})}>
                           {
                              (this.state.title === "F") ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />
                           }
                           <label>{getValue("donation_mad")}</label>
                         </div>
                         {
                          (this.state.locale == "en") ? 
                          (
                            
                           <div className="radioGroup" onClick={(e) => this.setState({ title: "Miss"})}>
                             {
                                (this.state.title === "Miss") ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />
                             }
                             <label>Miss</label>
                           </div>
                          )
                          : ""
                         }
                         {
                          (this.state.locale == "en") ? 
                          (
                            <div className="radioGroup" onClick={(e) => this.setState({ title: "Mrs."})}>
                             {
                                (this.state.title === "Mrs.") ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />
                             }
                             <label>Mrs.</label>
                           </div>
                          )
                          : ""
                         }
                       </div>
                       </div>
                       

                       <div className="inputRows">
                         <label>{getValue("donation_ch_name")}</label>
                         <input type="text" className="textField" onChange={(e) => {this.setState({ chineseName: e.target.value }); } } />
                       </div>

                       <div className="inputRows">
                         <label>*{getValue("donation_en_name")}</label>
                         <input type="text" className="lastNameTextField textField" onChange={(e) => { this.setState({ lastName: e.target.value });  this.validateFields(); } } />
                         <input type="text" className="firstNameTextField textField" onChange={(e) => this.setState({ firstName: e.target.value })} />
                         <span className={(this.state.displayValidateForm && this.state.lastName === "") ? "inputError" : "inputError hide"} >*{getValue("donation_err_name")}</span>
                       </div>

                       <div className="inputRows">
                         <label>*{getValue("donation_day_tel")}</label>
                         <input type="tel" className="textField" onChange={(e) => { this.setState({ tel: e.target.value });  this.validateFields(); } } />
                         <span className={(this.state.displayValidateForm && this.state.tel === "") ? "inputError" : "inputError hide"} >*{getValue("donation_err_tel")}</span>
                       </div>

                       <div className="inputRows">
                         <label>*{getValue("donation_email")}</label>
                         <input type="email" className="textField" onChange={(e) => { this.setState({ email: e.target.value });  this.validateFields(); } } />
                         <span className={(this.state.displayValidateForm && this.state.email === "") ? "inputError" : "inputError hide"} >*{getValue("donation_err_email")}</span>
                       </div>

                       <div className="inputRows">
                         <label>{getValue("donation_mail_add")}</label>
                         <input type="text" className="textField" onChange={(e) => this.setState({ address: e.target.value })}  />
                       </div>
                     </div>

                     <div className="rightContainer">
                       <div className="sectionHeader">
                         <div className="indicator"></div>
                         <h3>{getValue("donationMsg")}</h3>
                       </div>



                       <div className="inputRows">
                         <label>*{getValue("donation_amt")}</label>
                         <input type="number" className="textField" onChange={(e) => { this.setState({ amt: e.target.value });  this.validateFields(); } }  />
                         <span className={(this.state.displayValidateForm && this.state.amt <= 0) ? "inputError" : "inputError hide"} >*{getValue("donation_err_amt")}</span>
                       </div>

                       <div className="inputRows">
                         <label>*{getValue("donation_type")}</label>
                         <div className="radios">
                            {
                              (this.state.donationTypes || []).map(item => {
                                return (
                                  <div className="radioGroup" onClick={(e) => { this.setState({ donateType: item.title });  this.validateFields(); } }>
                                   {
                                      (this.state.donateType === item.title) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
                                   }
                                   <label>{item.title}</label>
                                 </div>
                                )
                              })
                            }
                           
                           <div className="radioGroup" onClick={(e) => { this.setState({ donateType: "其他"});  this.validateFields(); } }>
                             {
                                (this.state.donateType === "其他") ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
                             }
                             <div style={{ display: 'flex' }}>
                               <label style={{ width: '40px'}}>{getValue("donation_other")}</label>
                               <input style={{ height: '25px', 'fontSize': '16px', 'width': '100%'}} type="text" className="textField" onChange={(e) => this.setState({ donateTypeOther: e.target.value })}  />
                             </div>
                             
                             <span className={(this.state.displayValidateForm && this.state.donateType === "") ? "inputError" : "inputError hide"} >*{getValue("donation_err_type")}</span>
                           </div>
                         </div>
                       </div>

                       <div className="inputRows">
                         <label>{getValue("donation_receipt_arr")}</label>
                         <div className="radios">
                           <div className="radioGroup invoiceGroup" onClick={(e) => this.setState({ receipt: "Y"})}>
                             <div>
                               {
                                  (this.state.receipt === "Y") ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />
                               }
                               <label>{getValue("donation_receipt_headline")}</label>
                             </div>
                             <input type="text" className="textField" onChange={(e) => this.setState({ receiptTitle: e.target.value })}  />

                              <label>{getValue("donation_receipt_addr")}</label>
                              <input type="text" className="textField" onChange={(e) => this.setState({ receiptAddress: e.target.value })}  />
                           </div>
                           <div className="radioGroup" onClick={(e) => this.setState({ receipt: "N"})}>
                               {
                                  (this.state.receipt === "N") ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />
                               }
                             <label>{getValue("donation_no_receipt")}</label>
                           </div>
                          </div>
                       </div>


                       <div className="inputRows">
                         <label>*{getValue("donation_captcha")}</label>
                         

                       <Captcha onChange={(value) => { this.setState({ recapcha: value }); this.validateFieldsCaptcha(value); } } 
                       placeholder="" />
                             <span className={(this.state.displayValidateForm  && !this.state.recapcha) ? "inputError" : "inputError hide"} >*getValue("donation_err_captcha")}</span>
                       </div>


                       <div className="inputRows">

                        <div ref={ref => this.paypalValButton = ref } style={{ width: "60%", maxWidth: '700px', marginLeft: '0', marginTop: '20px' }}>
                          
                          <div className={(this.state.activeButton) ? "" : "hide"}>

                              <PayPalScriptProvider options={{ "currency": "HKD", "client-id": "AbGsVP2FlWjzDHTzej5NmbodR1CJ7X3WZSBKRNOM0iubAiuLtMk_hRwY3QlR0sn1u6XykSMMf6XVSJsd" }}>

                                  <PayPalButtons 
                                    style={{ layout: "horizontal", color: "blue", label: "pay" }}
                                    onInit={(data, actions) => {
                                      actions.enable();
                                    }}
                                    createOrder={(data, actions) => {
                                      return this.bindPaypalCreate()
                                    }}
                                    onApprove={(data, action) => { 
                                      let d =  this.bindPaypalComplete(data,action);
                                      // console.log(d);
                                      // return data;
                                    } }
                                   />

                              </PayPalScriptProvider>

                          </div>


                          <div className={(this.state.activeButton) ? "hide" : ""}>

                              <PayPalScriptProvider options={{ "currency": "HKD", "client-id": "AbGsVP2FlWjzDHTzej5NmbodR1CJ7X3WZSBKRNOM0iubAiuLtMk_hRwY3QlR0sn1u6XykSMMf6XVSJsd" }}>

                                  <PayPalButtons 
                                    style={{ layout: "horizontal", color: "blue", label: "pay" }}
                                    onInit={(data, actions) => {
                                      actions.disable();
                                    }}
                                    createOrder={(data, actions) => {
                                      return this.bindPaypalCreate()
                                    }}
                                    onApprove={(data, action) => this.bindPaypalComplete(data,action)}
                                   />

                              </PayPalScriptProvider>

                          </div>

                        </div>

                       </div>

                     </div>

                 </div>

                 {
                  (this.state.locale == "tc") ? (

                    <div>

                     <p className="remarks">

                     以上資料只會作為發收據及必須的聯絡使用，資料絕對保密。 假如您不欲接收任何華安聯合會的郵件，請致函通知我們。 
                      溫罄提示 - 奉獻者將會被接駁至PayPal的網上保安付款網頁，直接使用信用卡 (VISA / MASTER / AMERICAN EXPRESS) 
                      或登入PayPal戶口作網上奉献。所有信用卡資料將會以 SSL ( Secure Socket Layer ) 加密處理，
                      並不會儲存於華安聯合會的伺服器內。一次性奉獻交易經銀行批核後便不能取消，
                      付款金額會即時於你的信用卡或PayPal戶口內扣除 </p>

                      <div className="otherDonationMethod">
                        <p>其他奉献方法：</p>

                        <p>本港銀行劃線支票 <br />
                        劃線支票抬頭請寫上「華安聯合會有限公司」或「Chinese Union Mission Of Seventh-day Adventists Limited」 請在支票背後著明「奉獻項目名稱」「奉獻者姓名」「聯絡電話」 信封背面請寫上「回郵地址」</p>
                         
                        <p>郵寄往： <br />
                        華安聯合會 沙田小瀝源，源順圍28號，都會廣場12樓 </p>

                        <p>海外銀行支票<br />
                        劃線支票抬頭請寫上「General Conference of SDA Donation Center」 請在支票上著明「For the Chinese Union Mission, Project Title」 </p>

                        <p>郵寄往： <br />
                        General Conference of Seventh-day Adventists
                        <br />
                        Attn: Donation Center
                        <br />
                        12501 Old Columbia Pika, Silver Spring, MD 20904 </p>

                        <p>＊本地人士奉獻金額港幣100元或以上，憑正式收據可於所得稅中免稅</p>

                      </div>

                    </div>

                  ) : (

                    (this.state.locale == "sc") ? (

                      <div>

                         <p className="remarks">

                         以上资料只会作为发收据及必须的联络使用，资料绝对保密。假如您不欲接收任何华安联合会的邮件，请致函通知我们。
                          温罄提示 - 捐款者将会被接驳至PayPal的网上保安付款网页，直接使用信用卡 (VISA / MASTER / AMERICAN EXPRESS)
                          或登入PayPal户口作网上捐款。所有信用卡资料将会以 SSL ( Secure Socket Layer ) 加密处理，
                          并不会储存于华安联合会的伺服器内。一次性捐款交易经银行批核后便不能取消，
                          付款金额会即时于你的信用卡或PayPal户口内扣除 </p>

                          <div className="otherDonationMethod">
                            <p>其他奉献方法：</p>

                            <p>本港銀行劃線支票 <br />
                            划线支票抬头请写上「华安联合会有限公司」或「Chinese Union Mission Of Seventh-day Adventists Limited」 请在支票背后着明「奉献项目名称」「奉献者姓名」「联络电话」信封背面请写上「回邮地址」</p>
                             
                            <p>邮寄往： <br />
                            华安联合会 沙田小沥源，源顺围28号，都会广场12楼 </p>

                            <p>海外银行支票 <br />
                            划线支票抬头请写上「General Conference of SDA Donation Center」 请在支票上注明「For the Chinese Union Mission, Project Title」</p>

                            <p>邮寄往： <br />
                            General Conference of Seventh-day Adventists
                            <br />
                            Attn: Donation Center
                            <br />
                            12501 Old Columbia Pika, Silver Spring, MD 20904 </p>

                            <p>＊本地人士奉獻金額港幣100元或以上，憑正式收據可於所得稅中免稅</p>

                          </div>

                        </div>

                    ) : (

                      <div>

                         <p className="remarks">

                         Your personal information will be solely used to facilitate effective communication and issue receipt between you and the Chinese Union Mission. Donors will be connected to the PayPal online secure payment page directly using a credit card (VISA / MASTER / AMERICAN EXPRESS) or login to PayPal account for online donations. All credit card data will be SSL (Secure Socket Layer) encrypted, and will not be stored in CHUM’s server. A one-time donation approved by the bank after the transaction cannot be canceled and the payment amount will be immediately deducted from your credit card or PayPal account.
                         </p>

                          <div className="otherDonationMethod">
                            <p>You may also make a donation by cheque: </p>

                            <p>For Local Bank cheque <br />
                            Make cheque payable to "Chinese Union Mission Of Seventh-day Adventists Limited" Please provide your name, project, contact phone number and return mailing address for receipt.</p>
                             
                            <p>Mail To：<br />
                            Chinese Union Mission <br/>
                            12/F., Citimark, 28 Yuen Shun Circuit, <br/>
                            Siu Lek Yuen, Shatin, N.T., Hong Kong 
                            </p>

                            <p>For oversea Bank cheque <br />
                            Make cheque payable to “General Conference of SDA Donation Center” <br/>
                            memo “For the Chinese Union Mission, ‘Project name’”.</p>

                            <p>Mail To： <br />
                            General Conference of Seventh-day Adventists
                            <br />
                            Attn: Donation Center
                            <br />
                            12501 Old Columbia Pika, Silver Spring, MD 20904 </p>

                            <p>＊Tax deductible receipt (only for Hongkong Citizien).</p>

                          </div>

                        </div>

                      )

                  )
                 }

             </div>

             </div>

          <div ref={this._scroller} className="sideBar">
            <img className="logo blueLogo" src={blueLogo} />
            <img ref={this._scrollerLogo} className="logo" src={logo} />
          </div>


        <Footer />
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {

  };
}

export default withRouter(connect(
    mapStateToProps,
    { }
)(Donation));
