import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import logo from '../../images/logo.png';
import logoChi from '../../images/logoChi.png';
// import MenuIcon from '@material-ui/icons/Menu';

import { getComm, getDynamicContact, getNewsCat, RES_BASE_URL } from '../../redux/action';

import { getValue, getLocalLocale } from "../../constant/Locale";
import MenuIcon from '@material-ui/icons/Menu';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCoffee } from "@fortawesome/fontawesome-free-solid";

import './Header.css'; 

import { IconName } from "react-icons/fa";

class Header extends Component {

	constructor(props, context) {
	    super(props, context);
	    this.state = {
	      locale: getLocalLocale(),
	      isAboutMenuOpen: false,
	      isNewsMenuOpen: false,
	      isLastCallMenuOpen: false,
	      isCommitteeMenuOpen: false,
	      isSubMenusOpen: [false, false, false, false,
	      				   false, false, false, false,
	      				   false, false, false, false,
	      				   false, false, false ],
	      newsCategory: [{"id":0, "title": getValue("allNews")}],
	      committees: [],
	      abouts: [
	      	
	      ],
	      isAboutSubMenuOpen: false,
	      isNewsSubMenuOpen: false, 
	      isLastCallSubMenuOpen: false, 
	      isCommitteeSubMenuOpen: false,
	      isFaithAndYou: false
	    };
	  }

	toggleMenu = (isOpen) => (event) => {
	  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
	    return;
	  }

	  this.setState({"isMenuOpen": isOpen});
	};

	toggleSubMenu(index) {
		if( index == 0 ){
			this.setState({
				isAboutSubMenuOpen: !this.state.isAboutSubMenuOpen
			});	
		}
		else if( index == 1 ) {
			this.setState({
				isNewsSubMenuOpen: !this.state.isNewsSubMenuOpen
			});
		}
		else if( index == 2 ) {
			this.setState({
				isCommitteeSubMenuOpen: !this.state.isCommitteeSubMenuOpen
			});
		}
	}


	list = () => (
	    <div
	      role="presentation"
	      className="leftMenu"
	    >
		      <Divider />

		      <List>
		      	<ListItem button key={getValue('about_us')} onClick={() => {
				    
				    if( !this.state.isAboutSubMenuOpen ) {
				    	this.setState({
							isAboutSubMenuOpen: !this.state.isAboutSubMenuOpen,
							isNewsSubMenuOpen: false,
							isCommitteeSubMenuOpen: false
						});
				    }
				    else {
				    	this.setState({
							isAboutSubMenuOpen: !this.state.isAboutSubMenuOpen
						});
				    }

				    
		      	}}>
		        <ListItemText primary={getValue('about_us')} />
		        </ListItem>

		        <div className={(this.state.isAboutSubMenuOpen) ? "mobileSubMenu open" : "mobileSubMenu"}>
		        	<Divider />
		        	{
						 (this.state.abouts || []).map((item,idx) => {
						 	return (
						        <ListItem button key={item} onClick={() => window.location.href="/contact/"+idx}>
						          <ListItemText primary={item} />
						        </ListItem>
					 		);
						 })
					}
			        <Divider />
		        </div>

		        <ListItem button key={getValue('what_news')}>
		          <ListItemText onClick={() => window.location.href="/news/0"} primary={getValue('what_news')} />
		        </ListItem>

		        <ListItem button key={getValue('share_resources')} onClick={() => window.location.href="https://www.sdabible.org/"} >
		          <ListItemText primary={getValue('share_resources')} />
		        </ListItem>
		        <ListItem button key={getValue('faith_and_you')} onClick={() => window.location.href="/faithAndYou"} >
		          <ListItemText primary={getValue('faith_and_you')} />
		        </ListItem>


		        <ListItem button key={getValue('faith_and_you')} onClick={() => window.location.href="/ldsc"} >
		          <ListItemText primary={getValue('last_call')} />
		        </ListItem>

		        <ListItem button key={getValue('committee')} onClick={() => {
				    if( !this.state.isCommitteeSubMenuOpen ) {
				    	this.setState({
							isCommitteeSubMenuOpen: !this.state.isCommitteeSubMenuOpen,
							isAboutSubMenuOpen: false,
							isNewsSubMenuOpen: false
						});
				    }
				    else {
				    	this.setState({
							isCommitteeSubMenuOpen: !this.state.isCommitteeSubMenuOpen
						});
				    }
		      	}}>
		          <ListItemText primary={getValue('committee')} />
		        </ListItem>

		        <div className={(this.state.isCommitteeSubMenuOpen) ? "mobileSubMenu open" : "mobileSubMenu"}>
		        	<Divider />
		        	{
						 (this.state.committees || []).map((item,idx) => {
						 	return (
						 		
						        <ListItem button key={item.title} onClick={() => window.location.href="/departmentals/"+item.id+"/0"}>
						          <ListItemText primary={item.title} />
						        </ListItem>

						 	);
						 })
					}
			        <Divider />
		        </div>
		        
		        <ListItem button key={getValue('donation')} onClick={() => window.location.href="/donation"} >
		          <ListItemText primary={getValue('donation')} />
		        </ListItem>

		      </List>
		      
		      <Divider />

		      	<List>
			      	<ListItem button key="繁 ｜ 簡" >
			          <ListItemText onClick={() => this.switchLocale('tc')} primary="繁" />
			          <ListItemText onClick={() => this.switchLocale('sc')} primary="简" />
			          <ListItemText onClick={() => this.switchLocale('en')} primary="EN" />
			          <ListItemText primary="" />
			          <ListItemText primary="" />
			        </ListItem>
			    </List>

			    <Divider />
		    </div>
	       
	  );

	toggleMenu = (isOpen) => (event) => {
	    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
	      return;
	    }

	    this.setState({"isMenuOpen": isOpen});
	  };

  	componentWillMount() {

		document.documentElement.lang = localStorage.getItem('locale') || 'tc';
	    getNewsCat(this.state.locale).then(response => {
	      this.setState({ newsCategory: [...this.state.newsCategory, ...response.data] });
	    });
	    getComm(this.state.locale).then(response => {
	      this.setState({ committees: response.data });
	    }, this);
	    getDynamicContact(this.state.locale).then(response => {
	      let arr = (response.data || []).flatMap(item => item.title);
	      this.setState({ abouts: [...this.state.abouts, ...arr] });
	    }, this);
  	}

	componentDidMount() {
		(function() {
		    var cx = '4f8d9071c83769475';
		    var gcse = document.createElement('script');
		    gcse.type = 'text/javascript';
		    gcse.async = true;
		    gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
		    var s = document.getElementsByTagName('script')[0];
		    s.parentNode.insertBefore(gcse, s);
		  })();
	}



	switchLocale(locale) {
		localStorage.setItem('locale', locale);
		window.location.reload(false);
	}

	shouldOpenMenu(index, action) {
		if( index == 0 ){
			this.setState({
				isAboutMenuOpen: action
			});	
		}
		else if( index == 1 ) {
			this.setState({
				isNewsMenuOpen: action
			});
		}
		else if( index == 2 ) {
			this.setState({
				isCommitteeMenuOpen: action
			});
		} 
		else if( index == 3 ) {
			this.setState({
				isLastCallMenuOpen: action
			});
		} 
		else if( index == 4 ) {
			this.setState({
				isFaithAndYou: action
			});
		} 
	}

	toggleSubMenu(index) {
		var subMenuStatus = this.state.isSubMenusOpen;

		if( subMenuStatus[index] == false ) {
			//reset all to false
			for( var i=0; i<subMenuStatus.length; i++ ) {
				subMenuStatus[i] = false;
			}
		}

		subMenuStatus[index] = !subMenuStatus[index];
		this.setState({ isSubMenusOpen: subMenuStatus });
	}

	render() {
		
		return (
		<div className="header">

			<Drawer anchor={'right'} 
	                open={this.state.isMenuOpen} 
	                onClose={this.toggleMenu(false)}>
	          {this.list()}
	        </Drawer>
			
			<div className="topBanner">

				<div className="wrapper">
					<div className="innerWrapper">
						<h1 onClick={() => (window.location.href = "/" )} style={{ "cursor": "pointer" }} >Seventh-Day Adventist Church</h1>
						<div className="languageMenu">
							<FontAwesomeIcon icon={faSearch} />
							<a onClick={() => window.location.href = "/search"}>{getValue("search")}</a>
							<label style={{ "display": "initial" }} >|</label> 
							<a onClick={() => this.switchLocale('tc')}>{getValue("traditional_chi")}</a>
							<label style={{ "display": "initial" }} >|</label> 
							<a onClick={() => this.switchLocale('sc')}>{getValue("simpified_chi")}</a>
							<label style={{ "display": "initial" }} >|</label> 
							<a onClick={() => this.switchLocale('en')}>EN</a>
						</div>
						<div className="mobileMenu" onClick={this.toggleMenu(true)}>
							<MenuIcon className="mobileMenuIcon" />
							<span>MENU</span>
						</div>
					</div>
					
				</div>
			</div>
			<div className="bottomBanner">
				<div className="wrapper">
					<div className="innerWrapper">
						<div onClick={() => (window.location.href = "/" )} style={{ "cursor": "pointer" }}>
							<h1 className="logoChi">{getValue("chineseLogoLine1")} {getValue("chineseLogoLine2")}</h1>
							<h1 className="logoChi"></h1>
						</div>
						<ul className="topMenu">
							<li onMouseOver={() => this.shouldOpenMenu(0, true)}
								onMouseOut={() => this.shouldOpenMenu(0, false)}>
								<a href="#">{getValue("about_us")}</a>
								<ul className={(this.state.isAboutMenuOpen) ? "subMenu open" : "subMenu" } >
									{
										 (this.state.abouts || []).map((item,idx) => {
										 	return <li onClick={() => (window.location.href = "/contact/"+idx )}>{item}</li>
										 })
									}
								</ul>
							</li>
							<li onMouseOver={() => this.shouldOpenMenu(1, true)}
								onMouseOut={() => this.shouldOpenMenu(1, false)}>
								<a href="/news/0">{getValue("what_news")}</a>
								
							</li>
							<li><a href="https://www.sdabible.org/" target="_blank">{getValue("share_resources")}</a></li>
							<li onMouseOver={() => this.shouldOpenMenu(4, true)}
								onMouseOut={() => this.shouldOpenMenu(4, false)}>
								<a href="/faithAndYou">{getValue("faith_and_you")}</a>
								
							</li>

							<li onMouseOver={() => this.shouldOpenMenu(3, true)}
								onMouseOut={() => this.shouldOpenMenu(3, false)}>
								<a href="/ldsc">{getValue("last_call")}</a>
								
							</li>
							<li onMouseOver={() => this.shouldOpenMenu(2, true)}
								onMouseOut={() => this.shouldOpenMenu(2, false)}
							>
								<a href="#">{getValue("committee")}</a>
								<ul className={(this.state.isCommitteeMenuOpen) ? "subMenu open" : "subMenu" } >
									{
										 (this.state.committees || []).map((item,idx) => {
										 	return (
										 		<li onClick={() => (window.location.href = "/departmentals/"+item.id+"/0")} onMouseOver={() => this.toggleSubMenu(idx)}
													onMouseOut={() => this.toggleSubMenu(idx)}>
													{item.title}
													
												</li>

										 	);
										 })
									}
									
									<li>
									</li>
								</ul>
							</li>
							<li><a href="/donation">{getValue("donation")}</a></li>
						</ul>
					</div>
					
				</div>
			</div>
	    </div>

		);
	}

}

const mapStateToProps = (state) => {
  return {
    // error: getMembershipError(state.membershipReducer),
    // pending: getMembershipPending(state.membershipReducer),
    // registerSuccess: getMembershipRegisterStatus(state.membershipReducer),
    // userInfo: getMembershipUserInfo(state.membershipReducer),
    // changePwSuccess:  getChangePasswordSuccess(state.membershipReducer),
    // verifyEmailSuccess: getVerifyEmailSuccess(state.membershipReducer)
    // content_verison: getBibleContentVersion(state.bibleBookReducer),
    // secondary_content_verison: getBibleSecondaryContentVersion(state.bibleBookReducer)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  // register: registerAction,
  // login: loginAction,
  // logout: logoutAction,
  // changePw: changePwAction,
  // changeInfo: changeInfoAction,
  // sendForgetPwEmail: sendForgetPwEmailAction,
  // verifyEmail: verifyEmailAction
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Header));