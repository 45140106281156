import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { search, getBanner, RES_BASE_URL, PROD_BASE_URL } from '../../redux/action';

import './search.css'; 
import Header from '../common/Header';
import Footer from '../common/Footer';
import logo from '../../images/whitelogo.png';
import blueLogo from '../../images/logo-blue.png';
import videoSample from '../../images/videoThumbNailSample.png';
import newsSample from '../../images/newsThumbNailSample.png';
import newsSample2 from '../../images/newsThumbnailSample2.jpg';

import SearchIcon from '@material-ui/icons/Search';


// import logo from '../../images/logo.png';

import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";

class Search extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      searchResult: [],
    };
    this._scroller = React.createRef();
    this._scrollerLogo = React.createRef();
  }

  componentWillMount() {
    
    window.addEventListener('scroll', (e) => {
      this._scroller.current.style.backgroundColor = "rgba(47,85,127,"+(1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) )+")";
       this._scrollerLogo.current.style.opacity = (1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) );
    });

  }

  componentDidMount() { 

    getBanner(getLocalLocale(),4).then(response => {
      let data = (response.data.length > 0) ? response.data[0].img : ""
      this.setState({ banner: data });

    });

  }

  performSearch() {
    search({"keyword": this.state.query, "locale": getLocalLocale()}).then(response => {
      console.log(response.data);
      this.setState({ searchResult: response.data });
      console.log(response.data);
    });
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      console.log('do validate');
      this.performSearch();
    }
  }

  loadResultItems() {
     return (this.state.searchResult || []).map(searchResult => {
       return (
            <div className="resultItem">
               <a href={PROD_BASE_URL + searchResult['url']}><span className="resultTitle">{searchResult['title']}</span></a>
               <p className="resultDesc">{searchResult['desc']}</p>
               <p className="resultSource">{"SOURCE: " + PROD_BASE_URL + searchResult['url']}</p>
             </div>
        );
    });
  }

  render() {
    
    return (
      <div className="searchContainer">

          <Header />
        
          <div className="bodyContent">
             <div className="banner" style={{ "backgroundImage": "url(" + RES_BASE_URL + "storage/" + this.state.banner + ")" }}>
               <div className="wrapper">
                 <h1>{getValue("search")}</h1>
               </div>
             </div>                                                

             <div className="subCatBanner">
              <div className="wrapper">
                <input type="text" onChange={event => {this.setState({query: event.target.value})}} onKeyDown={this._handleKeyDown}/>
                <button type="submit" class="c-filter__button o-button u-space--right" onClick={() => this.performSearch()}>
                  <span class="u-icon u-icon--xs u-path-fill--white">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><title>Search</title><path d="M9.91,8.5,7.43,6A4,4,0,1,0,4,8a4,4,0,0,0,2-.57L8.5,9.91ZM2,4A2,2,0,1,1,4,6,2,2,0,0,1,2,4Z" fill="#777"></path></svg>
</span>{getValue("search")}</button>
               </div>
             </div>
             <div className="wrapper">

                 {
                  (this.state.searchResult.length == 0) ? <p style={{ "paddingLeft": "20px" }}>{getValue("search_no_result")}</p> :
                  this.loadResultItems()
                 }

             </div>
             
          </div>
          <div ref={this._scroller} className="sideBar">
            <img className="logo blueLogo" src={blueLogo} />
            <img ref={this._scrollerLogo} className="logo" src={logo} />
          </div>


        <Footer />
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {

  };
}

export default withRouter(connect(
    mapStateToProps,
    {  }
)(Search));
