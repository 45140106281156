import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SimpleDateTime  from 'react-simple-timestamp-to-date';

import { getComm, getCommIntro, getLastCall, getFaithAndYou, fileSize, RES_BASE_URL, CMS_BASE_URL } from '../../redux/action';

import './Committee.css'; 
import Header from '../common/Header';
import Footer from '../common/Footer';
import logo from '../../images/whitelogo.png';
import blueLogo from '../../images/logo-blue.png';
import leftArrow from '../../images/leftArrow.png';
import rightArrow from '../../images/rightArrow.png';
import videoSample from '../../images/videoThumbNailSample.png';
import newsSample from '../../images/newsThumbNailSample.png';
import newsSample2 from '../../images/newsThumbnailSample2.jpg';
import folderIcon from '../../images/folder.png';

import SearchIcon from '@material-ui/icons/Search';

import SlidesIcon from '@material-ui/icons/WebAsset';
import ImageIcon from '@material-ui/icons/Image';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import DocIcon from '@material-ui/icons/Assignment';
// import logo from '../../images/logo.png';

import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";

class Committee extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      selectedCategoryId: -1,
      activeCategory: 0,
      isScrolling: false,
      isCollecting: false,
      focusIndex: this.props.match.params.tab || 0,
      commIntro: [],
      commNews: [],
      commRes: [],
      commDir: [],
      commTemplates: [],
      currentDir: [],
      nameSortOrder: 0,
      dateSortOrder: -1,
      switchGrid: true,
      committees: [
        "傳道協會",
        "青年事工部",
        "傳媒中心",
        "公關傳播",
        "教育",
        "全球佈道",
        "健康事工部",
        "出版及文字佈道",
        "安息日學與個人佈道",
        "管家部",
        "婦女家庭事工",
        "禱告事工",
        "宗教自由",
        "兒童事工部",
        "特殊群體事工",
      ],
      currentIndex: 0
    };
    this._scroller = React.createRef();
    this._scrollerLogo = React.createRef();
  }

  componentWillMount() {

    window.addEventListener('scroll', (e) => {
      this._scroller.current.style.backgroundColor = "rgba(47,85,127,"+(1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) )+")";
       this._scrollerLogo.current.style.opacity = (1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) );
    });
  }

  componentDidMount() { 

    if (this.props.match.params.type == "ldsc") {

      getLastCall(this.state.locale).then(response => {
        this.setState({ committees: response.data });
        getCommIntro(this.state.locale,this.state.committees[this.state.currentIndex].id).then(res => {
          this.setState({ commTemplates: res.data });
        });
      }, this);

    } else if (this.props.match.params.type == "faithAndYou") {

      getFaithAndYou(this.state.locale).then(response => {
        this.setState({ committees: response.data });
        getCommIntro(this.state.locale,this.state.committees[this.state.currentIndex].id).then(res => {
          this.setState({ commTemplates: res.data });
        });
      }, this);

    } else {

      getComm(this.state.locale).then(response => {

        var currentPageIdx = 0;

        (response.data).map((item,idx) => {
          if (this.props.match.params.cat == item.id) {
            currentPageIdx = idx;
          }
        });

        console.log(currentPageIdx);

        this.setState({ committees: response.data, currentIndex: currentPageIdx });
        getCommIntro(this.state.locale,this.state.committees[currentPageIdx].id).then(res => {
          var pageIdx = (this.state.focusIndex >= res.data.length) ? 0 : this.state.focusIndex;
          console.log(pageIdx);
          console.log(res.data);
          let arr = (res.data || []).filter(item => (item.comm_resource_id != null) && (item.template_index == 2) );
          console.log(arr);
          if (arr.length > 0) {
            arr = arr[0].object.file_dir;
          } else {
            arr = [];
          }
          this.setState({  commTemplates: res.data, currentDir: [arr], commDir: arr });
        });
      }, this);

    }

  }

  displayIcons(doc,thumb) {
    if (doc == null) {
      return "";
    }

    let ext = doc.substr(doc.lastIndexOf(".")+1);

    switch(ext) {
      case 'pdf':
        return <img src={CMS_BASE_URL+thumb} />;

      case 'ppt':
        return <SlidesIcon className="icons" />;

      case 'doc':
        return <DocIcon className="icons" />;

      case 'png':
        return <img src={CMS_BASE_URL+doc} />;

      case 'jpg':
        return <img src={CMS_BASE_URL+doc} />;

      case 'gif':
        return <ImageIcon className="icons" />;
                             
    }

    return <ImageIcon className="icons" />;
  }

  openFile(file) {
    window.open(RES_BASE_URL+"comm/"+file,"_blank");
  }

  htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  mapDirectory(id) {
    let arr = (this.state.commTemplates || []).filter(item => (item.comm_resource_id == id) && (item.template_index == 2) );
    if (arr.length > 0) {
      return arr[0].object.file_dir;
    }
    return [];
  }

  swapSubMenu(item,index) {
    if (item.template_index == 2) {
      this.setState({ focusIndex: index, currentDir: [this.mapDirectory(item.id)], commDir: this.mapDirectory(item.id) });
    } else {
      this.setState({ focusIndex: index });      
    }
  }

  getHeading() {
    return this.state.committees[this.state.currentIndex].title;
  }

  openDir(key,value) {
    this.setState({ currentDir: [...this.state.currentDir, value.dir] });
  }

  openPrevDic() {
    var arr = [...this.state.currentDir];
    this.setState({ currentDir: arr.slice(0,-1) });
  }

  displayDirFiles(item) {

    console.log(item);

    var container = [];

    if (!this.state.switchGrid) {

      if (!("size" in item)) {

        Object.entries(item).map(([key,value]) => {
          container.push(
            <div>
              <div className="desktopRow">
               <div className="resourcesRows">
                 <p className="resourcesCol1"><SimpleDateTime dateSeparator="-" timeSeparator="-" format="YMD" showTime="0">{value.date}</SimpleDateTime></p>
                 <div className="resourcesCol2" onClick={() => this.openDir(key,value) } >
                   <img src={folderIcon} />
                   <p>{key}</p>
                 </div>
                 <p className="resourcesCol3"></p>
                 
               </div>
             </div>
             <div className="mobileRow">
               <div className="resourcesRows">
                 <div className="resourcesCol12">
                   <div className="resourcesCol2" onClick={() => this.openDir(key,value) } >
                     <img src={folderIcon} />
                     <div className="resourceCol2Content">
                       <p>{key}</p>
                       <p><SimpleDateTime dateSeparator="-" timeSeparator="-" format="YMD" showTime="0">{value.date}</SimpleDateTime></p>
                     </div>
                   </div>
                 </div>
                 <p className="resourcesCol3"></p>
                 
               </div>
             </div>
           </div>
          );
        });

      } else {

        container.push(
          <div>
            <div className="desktopRow">
             <div className="resourcesRows">
               <p className="resourcesCol1"><SimpleDateTime dateSeparator="-" timeSeparator="-" format="YMD" showTime="0">{item.date}</SimpleDateTime></p>
               <div className="resourcesCol2" onClick={() => (window.open(CMS_BASE_URL+item.link,"_blank")) }>
                 {this.displayIcons(item.link,item.thumbnail)}
                 <p>{item.name}</p>
               </div>
               <p className="resourcesCol3">{(item.size < 1024*1024) ? Math.round(item.size/1024)+"KB" : Math.round(item.size/(1024*1024))+"MB"}</p>
               
             </div>
           </div>
           <div className="mobileRow">
             <div className="resourcesRows">
               <div className="resourcesCol12">
                 <div className="resourcesCol2" onClick={() => (window.open(CMS_BASE_URL+item.link,"_blank")) }>
                   {this.displayIcons(item.link,item.thumbnail)}
                   <div className="resourceCol2Content">
                     <p>{item.name}</p>
                     <p><SimpleDateTime dateSeparator="-" timeSeparator="-" format="YMD" showTime="0">{item.date}</SimpleDateTime></p>
                   </div>
                 </div>
               </div>
               <p className="resourcesCol3">{Math.round(item.size/(1024*1024))}MB</p>
               
             </div>
           </div>
         </div>
        );

      }

    } else {

      if (!("size" in item)) {
        Object.entries(item).map(([key,value]) => {
          container.push(
            <div>
              <img onClick={() => this.openDir(key,value) } src={folderIcon} />
              <p>{key}</p>
            </div>
          )
        })
      } else {
        container.push(
          <div onClick={() => (window.open(CMS_BASE_URL+item.link,"_blank")) } >
            {this.displayIcons(item.link,item.thumbnail)}
            <p>{item.name}</p>
          </div>
        )
      }


    }

    return container;

  }

  switchCommittee(isNavigateToNext) {
    var currentIndex = this.state.currentIndex; 

    if( isNavigateToNext ) {
      currentIndex = currentIndex + 1 ;
    }
    else {
      if( currentIndex > 0 )
        currentIndex = currentIndex - 1;
      else 
        currentIndex = this.state.committees.length - 1;
    }

    currentIndex = currentIndex %  this.state.committees.length;

    getCommIntro(this.state.locale,this.state.committees[currentIndex].id).then(res => {
        this.setState({ commTemplates: res.data });
    });

    this.setState({ currentIndex: currentIndex });
  }

  resourceSortOrder(index) {
    
    if (index == 1) {
      this.setState({ dateSortOrder: (this.state.dateSortOrder == 0) ? 1 : (this.state.dateSortOrder*-1), nameSortOrder: 0 });
    } else if (index == 2) {
      this.setState({ nameSortOrder: (this.state.nameSortOrder == 0) ? 2 : (this.state.nameSortOrder*-1), dateSortOrder: 0 });
    }

  }

  render() {
    
    return (
      <div className="committeesContainer">

          <Header />
        
          <div className="bodyContent">
             <div className="banner" style={{ "backgroundImage": "url(" + RES_BASE_URL+"storage/" + this.state.committees[this.state.currentIndex].banner + ")" }} >
               <div className="wrapper">
                 <div className="navigationBar">
                     <img className="navigationIcon left" onClick={() => this.switchCommittee(false)} src={leftArrow} />
                     <h1>{this.getHeading()}</h1>
                     <img className="navigationIcon right" onClick={() => this.switchCommittee(true)} src={rightArrow} />
                 </div>
                
               </div>
             </div>                                                

             <div className="subCatBanner">
              <div className="wrapper">
                 <ul className="subCatMenu">

                  {
                    (this.state.committees[this.state.currentIndex] != null) ? 
                      (this.state.committees[this.state.currentIndex].menus || []).sort((a,b) => {
                        return a.sort_order - b.sort_order
                      }).map((item,idx) => {
                        return <li onClick={() => this.swapSubMenu(item,idx)}>{item.headline}</li>
                      })
                     : ""
                    
                  }

                 </ul>
               </div>
             </div>

             <div className="wrapper">

                {
                  (this.state.commTemplates || []).map((item,idx) => {
                    if (idx == this.state.focusIndex) {
                      switch (item.template_index) {
                        case 0:
                          return (
                            <div className="committeeIntroArea active" >
                              <div className="breadcrumb">
                                <a href={"/" + this.props.match.params.type}>{ getValue("committee_reousrce") }</a> &nbsp;&gt;&nbsp; <a href={"/" + this.props.match.params.type+"/"+item.comm_id+"/0"}>{ this.getHeading() }</a> &nbsp;&gt;&nbsp; <a href={"/" + this.props.match.params.type+"/"+item.comm_id+"/"+item.template_index}>{ item.object.headline }</a>
                              </div>

                              <div className="committeeIntroFrame" dangerouslySetInnerHTML={{ __html: (item.object.detail) }} ></div>
                                  
                            </div>
                          );

                        case 1:
                          return (

                           <div className="committeeNewsArea active" >
                             <div className="breadcrumb">
                               <a href={"/" + this.props.match.params.type}>{ getValue("committee_reousrce") }</a> &nbsp;&gt;&nbsp; <a href={"/" + this.props.match.params.type+"/"+item.comm_id+"/0"}>{ this.getHeading() }</a> &nbsp;&gt;&nbsp; <a href={"/" + this.props.match.params.type+"/"+item.comm_id+"/"+item.template_index}>{ (item.object && item.object.length > 0) ? item.object[0].headline : "" }</a>
                             </div>
                             <div className="newsListing">
                                 <div className="innerWrapper">

                                  {
                                    (item.object || []).map(object => {
                                      console.log(object);
                                      return (

                                        <div className="newsGroup">
                                         <div className="thumbnailArea">
                                           <img onClick={() => (window.location.href = "/" + this.props.match.params.type + "/news/"+item.comm_id+"/"+object.id ) } src={RES_BASE_URL+"storage/"+object.img_1} />
                                           <p>{getValue("last_update_on")} {object.postDate}</p>
                                         </div>
                                         
                                         <div className="descArea">
                                           <h4 onClick={() => (window.location.href = "/" + this.props.match.params.type + "/news/"+item.comm_id+"/"+object.id ) } >{object.title}</h4>
                                           <h5>{object.intro}</h5>
                                         </div>
                                       </div>

                                      )
                                    })
                                  }
                             
                                 </div>
                              </div>
                            </div>
                          );

                        case 2:
                          return (

                             <div className="committeeResourcesArea active" >
                               <div className="breadcrumb">
                                 <a href={"/" + this.props.match.params.type}>{ getValue("committee_reousrce") }</a> &nbsp;&gt;&nbsp; <a href={"/" + this.props.match.params.type+"/"+item.comm_id+"/0"}>{ this.getHeading() }</a> &nbsp;&gt;&nbsp; <a href={"/" + this.props.match.params.type+"/"+item.comm_id+"/"+item.template_index}>{ item.object.headline }</a>
                               </div>

                               {
                                (true) ? (

                                  <div>

                                      {

                                        (this.state.switchGrid) ? (

                                          <div className="resourcesHeading resourcesRows">
                                           {
                                            (this.state.currentDir.length <= 1) ? <div></div> :  <p onClick={() => this.openPrevDic()} >{getValue("back_btn")}</p>
                                           }

                                             <div className="optionRows">
                                               <p className="" onClick={() => (this.setState({ switchGrid: !this.state.switchGrid })) }>{(!this.state.switchGrid) ? getValue("grid_view") : getValue("list_view")}</p>
                                               <p className="" onClick={() => this.resourceSortOrder(1)}>{getValue("doc_date")}</p>
                                               <p className="" onClick={() => this.resourceSortOrder(2)}>{getValue("doc_title")}</p>
                                             </div>

                                           </div>


                                        ) : (
                                          <div>

                                          <div  className="resourcesRows" style={{ fontWeight: "bold" }}>
                                            {
                                              (this.state.currentDir.length <= 1) ? <div></div> :  <p onClick={() => this.openPrevDic()} >{getValue("back_btn")}</p>
                                             }

                                             <div className="optionRows">
                                               <p className="" onClick={() => (this.setState({ switchGrid: !this.state.switchGrid })) }>{(!this.state.switchGrid) ? getValue("grid_view") : getValue("list_view")}</p>
                                               
                                             </div>
                                          </div>

                                            <div className="resourcesHeading resourcesRows">
                                             

                                               <p className="resourcesCol4" onClick={() => this.resourceSortOrder(1)}>{getValue("doc_title")}/{getValue("doc_date")}</p>
                                               <p className="resourcesCol1" onClick={() => this.resourceSortOrder(1)}>{getValue("doc_date")}</p>
                                               <p className="resourcesCol2" onClick={() => this.resourceSortOrder(2)}>{getValue("doc_title")}</p>
                                               <p className="resourcesCol3">{getValue("doc_size")}</p>

                                             </div>

                                          </div>

                                        )


                                      }


                                   <div className="resourceGridBox">
                                     {

                                      (this.state.currentDir[this.state.currentDir.length-1] || []).sort((a,b) => {
                                        var v1 = a;
                                        var v2 = b;

                                        if (this.state.nameSortOrder == 0) {

                                          v1 = a.date;
                                          v2 = b.date;

                                          if (!("size" in a)) {
                                            v1 = Object.values(a)[0].date;
                                          }

                                          if (!("size" in b)) {
                                            v2 = Object.values(b)[0].date;
                                          }

                                          return ((this.state.dateSortOrder >= 0)) ? ((v1 < v2) ? -1 : 1) : ((v1 < v2) ? 1 : -1);
                                        } else {

                                          if (!("size" in a)) {
                                            v1 = Object.keys(a)[0];
                                          } else {
                                            v1 = a.name;
                                          }

                                          if (!("size" in b)) {
                                            v2 = Object.keys(b)[0];
                                          } else {
                                            v2 = b.name;
                                          }

                                          return ((this.state.nameSortOrder >= 0)) ? ((v1 < v2) ? -1 : 1) : ((v1 < v2) ? 1 : -1);
                                        }

                                      }).map( ritem => {

                                        
                                          return (
                                            <div className={(this.state.switchGrid) ? "resourceGridItem" : "resourceGridItem listItem" } >
                                              {
                                                this.displayDirFiles(ritem)
                                              }
                                            </div>
                                          )


                                      })

                                     }
                                   </div>

                                 </div>

                                ) : (

                                   <div className="resourcesTable">

                                     <div className="resourcesHeading resourcesRows">

                                       <p className="resourcesCol4" onClick={() => this.resourceSortOrder(this.state.nameSortOrder)}>內容/日期</p>
                                       <p className="resourcesCol1" onClick={() => this.resourceSortOrder(this.state.dateSortOrder)}>日期</p>
                                       <p className="resourcesCol2" onClick={() => this.resourceSortOrder(this.state.nameSortOrder)}>內容</p>
                                       <p className="resourcesCol3">檔案大小</p>
                                     </div>

                                     {

                                      (this.state.commRes || []).map(item => {

                                        if (item && false) {

                                          return (
                                            <div>
                                              <div className="desktopRow">
                                               <div className="resourcesRows">
                                                 <p className="resourcesCol1">{item.postDate}</p>
                                                 <div className="resourcesCol2" onClick={() => this.openFile(item.doc) }>
                                                   {this.displayIcons(item.doc,item.thumbnail)}
                                                   <p>{item.title}</p>
                                                 </div>
                                                 <p className="resourcesCol3">{item.size}MB</p>
                                                 
                                               </div>
                                             </div>
                                             <div className="mobileRow">
                                               <div className="resourcesRows">
                                                 <div className="resourcesCol12">
                                                   <div className="resourcesCol2" onClick={() => this.openFile(item.doc) }>
                                                     {this.displayIcons(item.doc,item.thumbnail)}
                                                     <div className="resourceCol2Content">
                                                       <p>{item.title}</p>
                                                       <p>{item.postDate}</p>
                                                     </div>
                                                   </div>
                                                 </div>
                                                 <p className="resourcesCol3">{item.size}MB</p>
                                                 
                                               </div>
                                             </div>
                                           </div>
                                          );
                                        }
                                        return "";
                                      })

                                     }

                                   </div>

                                )
                               }

                             </div>
                          );
                      }
                    }
                      
                  })
                }

             </div>
             
          </div>
          <div ref={this._scroller} className="sideBar">
            <img className="logo blueLogo" src={blueLogo} />
            <img ref={this._scrollerLogo} className="logo" src={logo} />
          </div>


        <Footer />
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {

  };
}

export default withRouter(connect(
    mapStateToProps,
    { }
)(Committee));
