import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getComm, getCommIntro, getFaithAndYou, getLastCall, getCommNews, fileSize, RES_BASE_URL } from '../../redux/action';

import './Committee.css'; 
import Header from '../common/Header';
import Footer from '../common/Footer';
import logo from '../../images/whitelogo.png';
import wechat from '../../images/wechat.png';
import blueLogo from '../../images/logo-blue.png';
import leftArrow from '../../images/leftArrow.png';
import rightArrow from '../../images/rightArrow.png';
import videoSample from '../../images/videoThumbNailSample.png';
import newsSample from '../../images/newsThumbNailSample.png';
import newsSample2 from '../../images/newsThumbnailSample2.jpg';

import SearchIcon from '@material-ui/icons/Search';

import SlidesIcon from '@material-ui/icons/WebAsset';
import ImageIcon from '@material-ui/icons/Image';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import DocIcon from '@material-ui/icons/Assignment';
// import logo from '../../images/logo.png';
import QRcode from 'qrcode.react';


import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  WeiboShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";


import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";

class CommitteeNews extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      selectedCategoryId: -1,
      activeCategory: 0,
      isScrolling: false,
      isCollecting: false,
      focusIndex: 1,
      commIntro: [],
      commNews: [],
      commRes: [],
      type: "committees",
      committees: [
        "傳道協會",
        "青年事工部",
        "傳媒中心",
        "公關傳播",
        "教育",
        "全球佈道",
        "健康事工部",
        "出版及文字佈道",
        "安息日學與個人佈道",
        "管家部",
        "婦女家庭事工",
        "禱告事工",
        "宗教自由",
        "兒童事工部",
        "特殊群體事工",
      ],
      currentIndex: 0
    };
    this._scroller = React.createRef();
    this._scrollerLogo = React.createRef();
  }

  componentWillMount() {
    

    window.addEventListener('scroll', (e) => {
      this._scroller.current.style.backgroundColor = "rgba(47,85,127,"+(1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) )+")";
       this._scrollerLogo.current.style.opacity = (1- window.pageYOffset/(document.body.scrollHeight - window.innerHeight) );
    });

  }

  componentDidMount() { 


    // if (this.props.match.params.type == "ldsc") {

    //   getLastCall(this.state.locale).then(response => {
    //     (response.data || []).map((item, idx) => {
    //       if (item.id == this.props.match.params.cat) {
    //         console.log(idx);
    //         this.setState({ currentIndex: idx });
    //       }
    //     });
    //     this.setState({ committees: response.data });
    //   }, this);

    // } else if (this.props.match.params.type == "faithAndYou") {

    //   getFaithAndYou(this.state.locale).then(response => {
    //     (response.data || []).map((item, idx) => {
    //       if (item.id == this.props.match.params.cat) {
    //         console.log(idx);
    //         this.setState({ currentIndex: idx });
    //       }
    //     });
    //     this.setState({ committees: response.data });
    //   }, this);

    // } else {

     

    // }
    
    getCommNews(this.state.locale, this.props.match.params.id).then(res => {
      this.setState({ commNews: res.data.comm_news, committees: res.data.comm });

      (res.data.comm || []).map(item => {
        if (item.is_faith_you == 1) {
          this.setState({ type: "faithAndYou" });
        } else if (item.is_last_call == 1) {
          this.setState({ type: "ldsc" });
        } else {
          this.setState({ type: "departmentals" });
           getComm(this.state.locale).then(response => {
            (response.data || []).map((item, idx) => {
              if (item.id == this.props.match.params.cat) {
                this.setState({ currentIndex: idx });
              }
            });
          }, this);
        }
      })

    });


  }

  displayIcons(doc) {
    if (doc == null) {
      return "";
    }

    let ext = doc.substr(doc.lastIndexOf(".")+1);

    switch(ext) {
      case 'pdf':
        return <PdfIcon className="icons" />;

      case 'ppt':
        return <SlidesIcon className="icons" />;

      case 'doc':
        return <DocIcon className="icons" />;

      case 'png':
        return <ImageIcon className="icons" />;

      case 'jpg':
        return <ImageIcon className="icons" />;

      case 'gif':
        return <ImageIcon className="icons" />;
                             
    }

    return <ImageIcon className="icons" />;
  }

  openFile(file) {
    window.open(RES_BASE_URL+"comm/"+file,"_blank");
  }

  htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  swapSubMenu(index) {
    // this.setState({ focusIndex: index });
    
      window.location.href = "/" + this.state.type +  "/"+this.props.match.params.cat+"/"+index;
  }

  getHeading() {
    return (this.state.committees.length > 0) ? this.state.committees[0].title : "";
  }

  render() {


    const shareUrl = window.location.href;
    const shareTitle = "";
    console.log(this.state.committees);
    
    return (
      <div className="committeesContainer committeesNewsContainer">

          <Header />
        
          <div className="bodyContent">
             <div className="banner">
               <div className="wrapper">
                 <div className="navigationBar">
                  {
                    (this.state.commNews || []).map(item => {
                      return <h1>{item.title}</h1>
                    })
                  }
                    
                 </div>
                
               </div>
             </div>                                                

             <div className="subCatBanner">
              <div className="wrapper">
                 <ul className="subCatMenu">
                    {
                      (this.state.committees[0] != null) ? 
                        (this.state.committees[0].menus || []).sort((a,b) => {
                          return a.sort_order - b.sort_order
                        }).map((item,idx) => {
                          return <li onClick={() => this.swapSubMenu(idx)}>{item.headline}</li>
                        })
                       : ""
                      
                    }
                 </ul>
               </div>
             </div>

             <div className="wrapper">

               <div className={ this.state.focusIndex == 1 ? "committeeNewsArea active" : "committeeNewsArea" }>
                   <div className="breadcrumb">
                    <a href={"/" + this.props.match.params.type}>{ getValue("committee_reousrce") }</a> 
                    &nbsp;&gt;&nbsp; 
                    <a href={"/" + this.props.match.params.type+"/"+this.props.match.params.cat+"/0"}>{ this.getHeading() }</a> 
                    &nbsp;&gt;&nbsp; 
                    { 

                      (this.state.committees[0] != null) ? 
                        (this.state.committees[0].menus || []).sort((a,b) => {
                          return a.sort_order - b.sort_order
                        }).map((item,idx) => {
                          if (item.headline == this.state.commNews[0].headline) {
                            return <a href={"/" + this.props.match.params.type+"/"+this.props.match.params.cat+"/"+item.template_index}>{ item.headline }</a>
                          }
                          return ""
                        })
                       : ""
                    }
                      
                   </div>
                   <div className="newsListing">
                       <div className="innerWrapper">

                         {
                            (this.state.commNews || []).map(item => {
                              return <div dangerouslySetInnerHTML={{ __html: (item.detail) }} ></div>
                         
                            })
                          }
                   
                       </div>
                       
                        <div className="searchBox">
                          
                          <FacebookShareButton
                            url={shareUrl}
                            quote={shareTitle}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>

                          <WhatsappShareButton
                            url={shareUrl}
                            title={shareTitle}
                            separator=":: "
                            className="Demo__some-network__share-button"
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>

                          <LineShareButton
                            url={shareUrl}
                            title={shareTitle}
                            className="Demo__some-network__share-button"
                          >
                            <LineIcon size={32} round />
                          </LineShareButton>

                          <WeiboShareButton
                            url={shareUrl}
                            title={shareTitle}
                            image={`""`}
                            className="Demo__some-network__share-button"
                          >
                            <WeiboIcon size={32} round />
                          </WeiboShareButton>
                          
                          <div className="wechatIcon">

                            <img src={wechat} />

                            <div className="wechatQrcode">
                              <QRcode value={shareUrl} />
                            </div>

                          </div>
                             
                        </div>
                   </div>

               </div>

             </div>
             
          </div>
          <div ref={this._scroller} className="sideBar">
            <img className="logo blueLogo" src={blueLogo} />
            <img ref={this._scrollerLogo} className="logo" src={logo} />
          </div>


        <Footer />
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {

  };
}

export default withRouter(connect(
    mapStateToProps,
    { }
)(CommitteeNews));
