import React, { Component } from 'react';
import {
  withRouter
} from "react-router-dom";

// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getComm, getFaithAndYou ,getCommIntro, fileSize, RES_BASE_URL } from '../../redux/action';

import './Committee.css'; 
import Header from '../common/Header';
import Footer from '../common/Footer';
import logo from '../../images/whitelogo.png';
import blueLogo from '../../images/logo-blue.png';
import leftArrow from '../../images/leftArrow.png';
import rightArrow from '../../images/rightArrow.png';
import videoSample from '../../images/videoThumbNailSample.png';
import newsSample from '../../images/newsThumbNailSample.png';
import newsSample2 from '../../images/newsThumbnailSample2.jpg';

import SearchIcon from '@material-ui/icons/Search';

import SlidesIcon from '@material-ui/icons/WebAsset';
import ImageIcon from '@material-ui/icons/Image';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import DocIcon from '@material-ui/icons/Assignment';
// import logo from '../../images/logo.png';

import { RESOURCE_URL } from "../../constant/Constant";
import { getValue, getLocalLocale } from "../../constant/Locale";

class FaithAndYou extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: getLocalLocale(),
      selectedCategoryId: -1,
      activeCategory: 0,
      isScrolling: false,
      isCollecting: false,
      focusIndex: this.props.match.params.tab || 0,
      commIntro: [],
      commNews: [],
      commRes: [],
      committees: [
        "傳道協會",
        "青年事工部",
        "傳媒中心",
        "公關傳播",
        "教育",
        "全球佈道",
        "健康事工部",
        "出版及文字佈道",
        "安息日學與個人佈道",
        "管家部",
        "婦女家庭事工",
        "禱告事工",
        "宗教自由",
        "兒童事工部",
        "特殊群體事工",
      ],
      currentIndex: this.props.match.params.cat || 0
    };
    this._scroller = React.createRef();
    this._scrollerLogo = React.createRef();
  }

  componentWillMount() {


    window.addEventListener('scroll', (e) => {
      this._scroller.current.style.backgroundColor = "rgba(47,85,127,"+(1- window.pageYOffset/document.body.scrollHeight)+")";
       this._scrollerLogo.current.style.opacity = (1- window.pageYOffset/document.body.scrollHeight);
    });
  }

  componentDidMount() { 
    getFaithAndYou(this.state.locale).then(response => {
      this.setState({ committees: response.data });
      getCommIntro(this.state.locale,this.state.committees[this.state.currentIndex].id).then(res => {
        this.setState({ commIntro: res.data.tbl_comm_intro, commNews: res.data.tbl_comm_news, commRes: res.data.tbl_comm_resource  });
      });
    }, this);
  }

  displayIcons(doc) {
    if (doc == null) {
      return "";
    }

    let ext = doc.substr(doc.lastIndexOf(".")+1);

    switch(ext) {
      case 'pdf':
        return <PdfIcon className="icons" />;

      case 'ppt':
        return <SlidesIcon className="icons" />;

      case 'doc':
        return <DocIcon className="icons" />;

      case 'png':
        return <ImageIcon className="icons" />;

      case 'jpg':
        return <ImageIcon className="icons" />;

      case 'gif':
        return <ImageIcon className="icons" />;
                             
    }

    return <ImageIcon className="icons" />;
  }

  openFile(file) {
    window.open(RES_BASE_URL+"comm/"+file,"_blank");
  }

  htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  swapSubMenu(index) {
    this.setState({ focusIndex: index });
  }

  getHeading() {
    return this.state.committees[this.state.currentIndex].title;
  }

  switchCommittee(isNavigateToNext) {
    var currentIndex = this.state.currentIndex; 

    if( isNavigateToNext ) {
      currentIndex = currentIndex + 1 ;
    }
    else {
      if( currentIndex > 0 )
        currentIndex = currentIndex - 1;
      else 
        currentIndex = this.state.committees.length - 1;
    }

    currentIndex = currentIndex %  this.state.committees.length;

    getCommIntro(this.state.locale,this.state.committees[currentIndex].id).then(res => {
        this.setState({ commIntro: res.data.tbl_comm_intro, commNews: res.data.tbl_comm_news, commRes: res.data.tbl_comm_resource  });
    });

    this.setState({ currentIndex: currentIndex });
  }

  render() {
    
    return (
      <div className="committeesContainer">

          <Header />
        
          <div className="bodyContent">
             <div className="banner">
               <div className="wrapper">
                 <div className="navigationBar">
                     <h1>{this.getHeading()}</h1>
                 </div>
                
               </div>
             </div>                                                

             <div className="subCatBanner">
              <div className="wrapper">
                 <ul className="subCatMenu">
                    <li onClick={() => this.swapSubMenu(0)}>{getValue("overview")}</li>
                    <li onClick={() => this.swapSubMenu(1)}>{getValue("what_news")}</li>
                    <li onClick={() => this.swapSubMenu(2)}>{getValue("resources")}</li>
                 </ul>
               </div>
             </div>

             <div className="wrapper">

                <div className={ this.state.focusIndex == 0 ? "committeeIntroArea active" : "committeeIntroArea" } >
                  <div className="breadcrumb">
                    { this.getHeading() } &nbsp;&gt;&nbsp; { getValue("intro") }
                  </div>

                  {
                    (this.state.commIntro || []).map(item => {
                      return <div className="committeeIntroFrame" dangerouslySetInnerHTML={{ __html: (item.detail) }} ></div>;
                    })
                  }
                      
                </div>

                 <div className={ this.state.focusIndex == 1 ? "committeeNewsArea active" : "committeeNewsArea" }>
                   <div className="breadcrumb">
                    { this.getHeading() } &nbsp;&gt;&nbsp; { getValue("what_news") }
                   </div>
                   <div className="newsListing">
                       <div className="innerWrapper">

                         {
                          (this.state.commNews || []).map(item => {
                            return (
                               <div className="newsGroup">
                                 <div className="thumbnailArea">
                                   <img src={RES_BASE_URL+"news/"+item.img_1} />
                                   <p>Last Updated on {item.postDate}</p>
                                 </div>
                                 
                                 <div className="descArea">
                                   <h4>{item.title}</h4>
                                   <h5>{item.intro}</h5>
                                   <div className="readMore" onClick={() => (window.location.href = "/committeesNews/"+item.cat1+"/"+item.id ) }>
                                     閱讀更多
                                   </div>
                                 </div>
                               </div>
                            );
                          })
                         }
                   
                       </div>
                    </div>
               </div>

               <div className={ this.state.focusIndex == 2 ? "committeeResourcesArea active" : "committeeResourcesArea" }>
                 <div className="breadcrumb">
                    { this.getHeading() } &nbsp;&gt;&nbsp; { getValue("resources") }
                 </div>

                 <div className="resourcesTable">

                   <div className="resourcesHeading resourcesRows">

                     <p className="resourcesCol4">內容/日期</p>
                     <p className="resourcesCol1">日期</p>
                     <p className="resourcesCol2">內容</p>
                     <p className="resourcesCol3">檔案大小</p>
                   </div>

                   {

                    (this.state.commRes || []).map(item => {
                      if (item) {

                        return (
                          <div>
                            <div className="desktopRow">
                             <div className="resourcesRows">
                               <p className="resourcesCol1">{item.postDate}</p>
                               <div className="resourcesCol2" onClick={() => this.openFile(item.doc) }>
                                 {this.displayIcons(item.doc)}
                                 <p>{item.title}</p>
                               </div>
                               <p className="resourcesCol3">{item.size}MB</p>
                               
                             </div>
                           </div>
                           <div className="mobileRow">
                             <div className="resourcesRows">
                               <div className="resourcesCol12">
                                 <div className="resourcesCol2" onClick={() => this.openFile(item.doc) }>
                                   {this.displayIcons(item.doc)}
                                   <div className="resourceCol2Content">
                                     <p>{item.title}</p>
                                     <p>{item.postDate}</p>
                                   </div>
                                 </div>
                               </div>
                               <p className="resourcesCol3">{item.size}MB</p>
                               
                             </div>
                           </div>
                         </div>
                        );
                      }
                      return "";
                    })

                   }

                 </div>



               </div>

             </div>
             
          </div>
          <div ref={this._scroller} className="sideBar">
            <img className="logo blueLogo" src={blueLogo} />
            <img ref={this._scrollerLogo} className="logo" src={logo} />
          </div>


        <Footer />
      </div>
    );
  }


}

const mapStateToProps = (state) => {
  return {

  };
}

export default withRouter(connect(
    mapStateToProps,
    { }
)(FaithAndYou));
